import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "./popupFooter.css";
import svgSupport from "../../assets/svg/support.svg";
import svgPhone from "../../assets/svg/phone.svg";
import svgEnvelope from "../../assets/svg/envelope.svg";

export const Footer = ({ location }) => {
  const [popup, setPopup] = useState(false);
  const supportPerson = process.env.REACT_APP_SITE === "mivivus" ? "/img/footer/support-mivivus.png" : "/img/footer/support.webp";
  return (
    <>
      <section className="text-white flex flex-col z-20">
        <div className="bg-gradient-to-l from-gray-dark to-gray-regular w-full flex justify-center">
          <div className="container justify-between flex flex-col-reverse lg:flex-row p-4">
            <div className="flex flex-col-reverse items-center gap-4 lg:flex-row">
              <img src={svgSupport} alt="customer service icon" className="w-20 hidden lg:flex" />
              <img src={supportPerson} alt="customer service" className="-mb-4 w-40" />
              <div className="flex flex-col gap-2 lg:text-left items-center text-center lg:items-start justify-center">
                <h2 className="text-xl font-bold">¿Necesitas ayuda?</h2>
                <p className="text-lg font-bold">Contáctanos</p>
                <p className="text-md">Por favor, si tienes alguna duda, ponte en contacto con nosotros.</p>
              </div>
            </div>
            <div className="flex flex-col py-12 lg:py-0 items-center lg:items-end justify-center gap-4">
              <div className="flex gap-4">
                <div className="flex items-center gap-2 font-bold">
                  <img src={svgPhone} width={20} />
                  <a href="tel:5567170750">(0155) 6717 0750</a>
                </div>
                <a href="mailto:info@doctorpeso.com.mx" className="flex items-center gap-2 font-bold">
                  <img src={svgEnvelope} width={20} />
                  <span>info@doctorpeso.com.mx</span>
                </a>
              </div>
              <div className="flex flex-col items-center text-center lg:text-left lg:items-end">
                <p>
                  <strong>Nuestro horario de atención al cliente es:</strong>
                </p>
                <p className="text-center lg:text-right">
                  Lunes a Viernes de 8:00 a 20:00 y <br />
                  Sábados de 8:00 a 14:00
                </p>
              </div>
            </div>
            <img src={svgSupport} alt="customer service icon" className="w-20 flex lg:hidden self-center" />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="container px-4 py-12 lg:py-4 gap-4 lg:justify-end justify-center items-center flex flex-col lg:flex-row">
            <Link to={location === "manteinance" ? "#" : "/contenido/aviso-de-privacidad"} className="text-gray-light hover:text-gray-dark transition-all">
              Aviso de privacidad
            </Link>
            <Link to={location === "manteinance" ? "#" : "/contenido/terminos-y-condiciones"} className="text-gray-light hover:text-gray-dark transition-all">
              Términos y Condiciones
            </Link>

            <a href="https://www.condusef.gob.mx/" target="_blank">
              <img width="90px" src="/img/condusef.jpeg" alt="buro" />
            </a>
            <a href="https://www.buro.gob.mx/" target="_blank">
              <img width="50px" src="/img/logo-buro.png" alt="buro" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};
