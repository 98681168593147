import React from "react";
import PropTypes from "prop-types";

const Button = ({ children, onClick = () => null, className = "", icon }) => {
  return (
    <p onClick={onClick} role="button" className={`${className} bg-green-primary flex justify-center hover:bg-green-clear active:bg-green-dark transition-all font-extra-bold text-white py-3 px-12 rounded-full cursor-pointer text-lg`}>
      {icon && <img src={icon} width={24} className="mr-2 object-contain" />}
      {children}
    </p>
  );
};

Button.propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Button.defaultValues = {
  onClick: () => null,
  className: "",
};

export default Button;
