import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import cookie from "react-cookies";
import { getToken, checkCode, sendCodeBySMS } from "../../services/api";
import { BallBeat } from "react-pure-loaders";
import Button from "../common/Button";
import { useLocation } from "../../hooks/location";
import svgCancel from "../../assets/svg/cancel.svg";

const idProduct = 4;

const PasswordRecoveryMobileConfirmation = (props) => {
  const [idCustomer, setIdCustomer] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [celular, setCelular] = useState(null);
  const [codeError, setCodeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstSent, setFirstSent] = useState(false);
  const [sentBySMS, setSentBySMS] = useState(false);
  const [timeToSend, setTimeToSend] = useState(59);
  const { location, ip } = useLocation();

  useEffect(() => {
    let getCellphone = sessionStorage.getItem("mobile");
    let getIdCustomer = parseInt(sessionStorage.getItem("idCustomer"));
    if (!getCellphone || !getIdCustomer) return props.history.push("/recupera-opcion");
    setCelular(getCellphone);
    setIdCustomer(getIdCustomer);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("loggedUser") && cookie.load("token")) props.history.push("/dashboard/initial");
  }, []);

  //Interval to send sms
  useEffect(() => {
    if (!sentBySMS) {
      const cTime = parseInt(sessionStorage.getItem("timeToSendNIP_password")) || 59;
      if (cTime < 59) {
        setSentBySMS(true);
        setFirstSent(true);
      } else {
        setTimeToSend(59);
        sessionStorage.setItem("timeToSendNIP_password", 59);
      }
    } else {
      const interval = setInterval(() => {
        let at = parseInt(sessionStorage.getItem("timeToSendNIP_password")) || 59;
        at = at - 1;
        setTimeToSend(at);
        sessionStorage.setItem("timeToSendNIP_password", at);
        if (at === 0) {
          setSentBySMS(false);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [sentBySMS]);

  useEffect(() => {}, []);

  const handleCode = async () => {
    setLoading(true);
    let data = {
      idCustomer,
      code: codigo,
      idProduct,
      isNIP: false,
      userAgent: navigator.userAgent,
      clientIP: ip,
      coordinates: location,
    };
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;
    checkCode(data, validToken)
      .then((res) => {
        if (res.data.idStatus === 1) {
          sessionStorage.setItem("code-valid", "true");
          sessionStorage.removeItem("timeToSendNIP_password");
          return props.history.push("/nuevo-password");
        }
        setCodeError(true);
        setLoading(false);
      })
      .catch((err) => {
        setCodeError(true);
        setLoading(false);
      });
  };

  const sendAgain = async () => {
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;
    const data = {
      idProduct,
      idCustomer,
    };
    return sendCodeBySMS(data, validToken)
      .then(() => setSentBySMS(true))
      .catch((err) => console.log(err));
    // return sendCodeByWA(data, validToken)
    //   .catch((err) => console.log(err))
    //   .finally(() => {
    //     sendCodeBySMS(data, validToken)
    //       .then(() => console.log("Message sent"))
    //       .catch((err) => console.log(err));
    //   });
  };

  return (
    <div className="bg-white relative w-full flex justify-center items-start flex-1">
      <div className="container flex flex-col gap-4 p-4 ">
        <div className="flex flex-col gap-4 py-4 max-w-4xl w-full self-start">
          <div className="w-full flex flex-col gap-2">
            <h1 className="text-3xl font-extra-bold text-black-primary">Recuperación por SMS</h1>
            <p>
              {!firstSent ? (
                <span>
                  Da click en el botón de <b>Enviar código</b> para recibir un SMS a tu número:
                  <strong className="font-bold"> {`******${celular?.substr(celular.length - 4)}`}</strong>.
                </span>
              ) : (
                <span>Ingresa el código enviado.</span>
              )}
            </p>
          </div>
          <div className="w-full flex flex-col gap-4 rounded-lg bg-[#f0f0f0] p-4">
            {!sentBySMS && !firstSent && (
              <Button
                className="self-start"
                onClick={() => {
                  sendAgain();
                  setFirstSent(true);
                }}
              >
                Enviar código
              </Button>
            )}
            <div className={`flex flex-col gap-2 xl:w-1/2`}>
              <label className="font-bold">Código para recuperación </label>
              <input
                disabled={!firstSent}
                className={`w-full ${!firstSent ? "bg-[#e0e0e0] pointer-events-none" : "bg-white"} ${codeError ? "border-red" : ""}`}
                type="text"
                name="codigo"
                placeholder="Código para recuperación"
                maxLength={4}
                value={codigo}
                onChange={(e) => setCodigo(e.target.value)}
              />
              {codeError ? <span className="text-red">Código incorrecto</span> : null}
            </div>
            <Button className={`lg:w-56 ${!firstSent ? "bg-[#e0e0e0] pointer-events-none" : ""}`} onClick={handleCode}>
              {loading ? <BallBeat color={"#fff"} loading /> : "Confirmar"}
            </Button>
            {
              firstSent && (
                <small>
                  Si no lo has recibido, puedes volver a solicitarlo{" "}
                  {sentBySMS ? (
                    <strong>en {timeToSend} segundos</strong>
                  ) : (
                    <strong
                      onClick={() => {
                        sendAgain();
                        setFirstSent(true);
                      }}
                      className="text-blue-primary font-bold cursor-pointer"
                    >
                      Dando click aquí
                    </strong>
                  )}
                </small>
              )

              //<Countdown date={Date.now() + 60000} renderer={renderer} />
            }

            <Link className="text-blue-primary hover:text-blue-light active:text-blue-dark font-bold transition-all" to="/recupera-opcion">
              Cambia opción de recuperación
            </Link>
            <div className="flex gap-2 cursor-pointer">
              <img src={svgCancel} width={20} alt="cancel" />
              <Link className="text-red font-bold transition-all" to="/login">
                Cancelar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PasswordRecoveryMobileConfirmation);
