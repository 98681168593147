import React from "react";
import Sld from "react-rangeslider";

export const RegisterSteps = ({ props, currentStep, title = "Registro" }) => {
  return (
    <Sld
      min={0}
      max={5}
      value={currentStep}
      labels={{
        0: (
          <span className="text-gray-light text-sm">
            Paso {currentStep}/5. {title}
          </span>
        ),
        1: "",
      }}
      disabled={true}
      orientation="horizontal"
      tooltip={false}
      className="register-slider"
    />
  );
};
