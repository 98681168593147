import React from "react";
import svgBank from "../../../assets/svg/bank.svg";
import svgDebit from "../../../assets/svg/debit.svg";
import svgChevronRight from "../../../assets/svg/chevron-right.svg";
import svgCancel from "../../../assets/svg/cancel.svg";

const ChangePayment = ({ setSection }) => {
  return (
    <div className="flex flex-col gap-4  lg:max-w-2xl">
      <h3 className="text-xl font-bold text-black-primary">Cambia aquí tu CLABE o Tarjeta de Débito</h3>
      <div className="w-full flex flex-col gap-4 rounded-lg bg-[#f0f0f0] p-4 lg:max-w-4xl">
        <div onClick={() => setSection("payment-bank")} className="flex gap-4 items-center justify-between p-4 cursor-pointer bg-[#e0e0e0] rounded-lg">
          <div className="flex gap-4 items-center">
            <img src={svgBank} width="32" alt="bank" />
            <p className="font-bold">Cuenta bancaria</p>
          </div>
          <img src={svgChevronRight} width={20} alt="chev right" />
        </div>
        <div onClick={() => setSection("payment-debit")} className="flex gap-4 items-center justify-between p-4 cursor-pointer bg-[#e0e0e0] rounded-lg">
          <div className="flex gap-4 items-center">
            <img src={svgDebit} width="32" alt="debit" />
            <p className="font-bold">Tarjeta de débito</p>
          </div>
          <img src={svgChevronRight} width={20} alt="chev right" />
        </div>
        <div className="flex gap-2 cursor-pointer items-center" onClick={() => setSection("home")}>
          <img src={svgCancel} width={20} alt="cancel" />
          <span className="text-red font-bold transition-all">Cancelar</span>
        </div>
      </div>
    </div>
  );
};

export default ChangePayment;
