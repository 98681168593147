import React, { useState, useEffect } from "react";
import "./newStyles.scss";
import Default from "./dashboardPieces/Default";
import Move from "./dashboardPieces/Move";
import More from "./dashboardPieces/More";
import cookie from "react-cookies";
import { getStatus } from "../../services/api";
import { checkLoan, checkExtension } from "./dashboardPieces/dashboardChecks.js";
import svgUser from "../../assets/svg/user.svg";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { BallClipRotate } from "react-pure-loaders";

const idProduct = 4;

const NewDashboard = (props) => {
  const [flux, setFlux] = useState("default");
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState({});
  const [bannerId, setBannerId] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [canSolicitMore, setCanSolicitMore] = useState(true);
  const [canSolicitMove, setCanSolicitMove] = useState(true);
  const [dropOptions, setDropOptions] = useState([]);

  const defaultOption = dropOptions[0];
  const handleDropdownChange = (e) => {
    window.scrollTo(0, 2000);
    switch (e.value) {
      case "pay":
      //return props.history.push("/dashboard/general");
      case "move":
      //return props.history.push("/dashboard/extension");
      case "more":
      //return props.history.push("/dashboard/additional-amount");
      default:
        break;
    }
  };

  useEffect(() => {
    if (canSolicitMore) {
      setDropOptions([
        { value: "pay", label: "MI PRÉSTAMO" },
        { value: "move", label: "EXTENSIONES" },
        { value: "more", label: "SOLICITA MÁS DINERO" },
      ]);
    } else {
      setDropOptions([
        { value: "pay", label: "MI PRÉSTAMO" },
        { value: "move", label: "EXTENSIONES" },
      ]);
    }
  }, [canSolicitMore]);

  const checkUser = (user, token) => {
    setLoading(true);
    return getStatus(idProduct, user.customerId, false, token)
      .then((res) => {
        if (res.status && res.data.idStatus === 4) return props.history.push("/denied");
        if (res.status === 200) {
          setBannerId(res.data.bannerId);
          if (res.data.idStatus === 1) {
            if (res.data.idSubStatus === 184) return props.history.push("/registration");
            if (res.data.idSubStatus === 196) return props.history.push("/pre-approved");
            if (res.data.idSubStatus === 203) return props.history.push("/pre-approved");
            if (res.data.idSubStatus === 206) return props.history.push("/application-review");
            if (res.data.idSubStatus === 217) return props.history.push("/dashboard/id");

            if (res.data.idSubStatus === 218) return props.history.push("/application-complete");
            if (res.data.idSubStatus === 219) return props.history.push("/application-complete");
            if (res.data.idSubStatus === 248) return props.history.push("/application-complete");
            if (res.data.idSubStatus === 254) return props.history.push("/rejected");
            if (res.data.idSubStatus === 255) return props.history.push("/identity-validated");
            if (res.data.idSubStatus === 257) return props.history.push("/identity-validated");
            if (res.data.idSubStatus === 270) return props.history.push("/loan-review");
            if (res.data.idSubStatus === 271) return props.history.push("/loan-review");
            if (res.data.idSubStatus === 273) return props.history.push("/loan-review");

            return props.history.push("/registration");
          }
          if (res.data.idStatus === 7) {
            setLoading(false);
            return;
          }
          if (res.data.idStatus === 6) {
            if (res.data.idSubStatus === 15) return props.history.push("/application-complete");
          }
          if (res.data.idStatus === 8) return props.history.push("/dashboard");
          if (res.data.idStatus === 4) {
            return props.history.push("/denied");
          }
        }
        setLoading(false);
        setServerError(true);
        return props.history.push({ pathname: "/error", state: { endpoint: "getStatus", status: res.status } });
      })
      .catch((err) => setServerError(true));
  };

  const checkToken = () => {
    if (user.eMail === "demo@demo.com") return;
    let getToken = cookie.load("token");
    if (!getToken) return props.history.push("/login");
  };

  useEffect(() => {
    let getUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (!getUser) return props.history.push("/login");
    setUser(getUser);
    let approved = sessionStorage.getItem("APP");
    if (approved === "no") return props.history.push("/denied");
    if (getUser.eMail === "demo@demo.com") return setBannerId(1);
    let getToken = cookie.load("token");
    if (!getToken) return props.history.push("/login");
    checkUser(getUser, getToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history]);

  useEffect(() => {
    if (isInitialMount) {
      setIsInitialMount(false);
    } else {
      //checkLoan(user, setServerError, setCanSolicitMore);
      //checkExtension(setCanSolicitMove);
    }
  }, [user]);

  return (
    <>
      <div className="bg-white relative w-full flex justify-center flex-1">
        <div className="container flex flex-col gap-8 px-4 py-12 self-start">
          <div className="w-full flex gap-2 justify-between flex-col lg:flex-row">
            <h1 className="text-3xl font-extra-bold text-green-primary">Mi préstamo</h1>
            <div className="flex gap-2 items-center">
              <span className="font-bold text-black-primary">{user?.fullName}</span>
              <img src={svgUser} width={20} alt="user" />
            </div>
          </div>
          {loading ? <BallClipRotate loading className="text-green-dark" /> : <Default history={props.history} setBalance={setBalance} bannerId={bannerId} flux={flux} setFlux={setFlux} checkToken={checkToken} canSolicitMore={canSolicitMore} />}
        </div>
      </div>
    </>
  );
};

export default NewDashboard;
