import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import svgInfoBlue from "../../assets/svg/info-blue.svg";

export const Obtain = () => {
  return (
    <div className="flex justify-center items-center flex-1 flex-col ">
      <div className="container px-4 py-12 flex gap-4 flex-col lg:flex-row">
        <div className="w-full lg:w-2/3">
          <h2 className="text-3xl font-extra-bold">¡Tres sencillos pasos para disponer del dinero en tu cuenta de la manera más conveniente!</h2>
          <ol className="list-decimal p-0 m-0 text-left flex flex-col gap-4">
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-start justify-items-center my-4 before:content-['1'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              <p>
                <strong>Elige el monto y plazo en el simulador, luego presiona “SOLICÍTALO YA”</strong>
              </p>
            </li>
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-start justify-items-center my-4 before:content-['2'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              <p>
                <strong>Continúa llenando el formulario de registro. </strong>¡No te preocupes! Te vamos a hacer varias preguntas respecto a tu información personal y bancaria. Esta información es sumamente importante para poder brindarte el préstamo de forma rápida y segura. Toda la información es
                confidencial y sólo será utilizada para validar tu préstamo.
              </p>
            </li>
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-start justify-items-center my-4 before:content-['3'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              <p>
                Luego de brindar toda la información solicitada, <strong>espera unos momentos por nuestra confirmación.</strong> Si la solicitud es aprobada, serás informado en tu perfil en doctorpeso.com.mx y también mediante un correo electrónico y mensaje de texto. El dinero solicitado será
                transferido a tu cuenta bancaria a la brevedad.
              </p>
            </li>
          </ol>
        </div>
        <div className="w-full lg:w-1/3 flex align-start rounded-lg bg-blue-lighter justify-start p-4 gap-4 h-auto self-center">
          <img src={svgInfoBlue} alt="obtain" width={24} height={24} className="self-start" />
          <div className="text-left font-light flex-1 text-blue-primary">
            Si ya estás registrado previamente en{" "}
            <a href="/login" className="font-bold text-blue-primary">
              doctorpeso.com.mx
            </a>
            ,{" "}
            <Link to="/login" className="underline text-blue-primary">
              Ingresa en tu perfil
            </Link>{" "}
            utilizando tu correo electrónico, contraseña y respetando las guías disponibles para solicitar tu préstamo por primera vez o préstamos subsecuentes. <br /> Si ya tienes un préstamo con nosotros, te podemos ofrecer un monto adicional o recorrer la fecha de pago de tu préstamo.
          </div>
        </div>
      </div>
    </div>
  );
};
