import React, { useEffect, useState } from "react";
import { getToken, getOXXOPaymentReference, getOXXOBEPaymentReference } from "../../../../services/api";
import { BallClipRotate } from "react-pure-loaders";
import { momentEs } from "../../../../services/moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../common/Button";

const idProduct = 4;

const Cash = ({ balance, setCargos }) => {
  const [loading, setLoading] = useState(false);
  const [reference, setReference] = useState(null);
  const [sentByMail, setSentByMail] = useState(false);

  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      setCargos("resumen");
    }
  };

  const oxxoData = async (user, validToken) => {
    const data = {
      idProduct,
      idCustomer: user.customerId,
    };
    getOXXOPaymentReference(data, validToken)
      .then((res) => {
        setReference(res.data.paymentReference);
        setSentByMail(true);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const oxxoBeData = async (user, validToken) => {
    const data = {
      idProduct,
      idCustomer: user.customerId,
    };
    getOXXOBEPaymentReference(data, validToken)
      .then((res) => {
        setReference(res.data.paymentReference);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const getData = async (user) => {
    // Here goes post to get user debt instead of session storage
    let response = await getToken();
    if (!response) return setLoading(false);
    let validToken = response.data.token;
    oxxoData(user, validToken).then((data) => console.log(data));
  };

  useEffect(() => {
    const fn = async () => {
      let user = JSON.parse(sessionStorage.getItem("loggedUser"));
      let response = await getToken();
      if (!response) return setLoading(false);
      let validToken = response.data.token;
      oxxoBeData(user, validToken).then((data) => console.log(data));
    };
    fn();
  }, []);

  const requestRef = () => {
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (demoUser || user.eMail === "demo@demo.com") {
      return setReference(1234789456123);
    }
    setLoading(true);
    if (user) {
      getData(user);
    } else setLoading(false);
  };
  return (
    <div className="flex flex-col gap-4 py-8 px-4">
      <>
        <h4 className="text-xl font-bold text-black-primary">Efectivo</h4>

        <ol>
          <li>Acude a cualquier tienda participante (OXXO) y realiza tu pago en efectivo con el código de referencia.</li>
          <li>Dando click en el botón de abajo te enviaremos tu número de referencia por correo electrónico.</li>
        </ol>
        {reference && (
          <p>
            Número de referencia: <strong className="font-bold">{reference}</strong>
          </p>
        )}
        {sentByMail ? (
          <p className="border-2 border-green-dark flex items-center justify-center p-4">Hemos enviado la referencia a tu correo electrónico</p>
        ) : (
          <Button onClick={requestRef} className="text-center self-center">
            {loading ? <BallClipRotate loading color="white" /> : "Enviar referencia "}
          </Button>
        )}
      </>
    </div>
  );
};

export default Cash;
