import React, { useState, useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { getToken, getCustomerBalance, getFilledContract, getAccountStatement, getAnalytics, getStatus } from "../../../services/api";
import { BallClipRotate } from "react-pure-loaders";
import { momentEs } from "../../../services/moment";
import TagManager from "react-gtm-module";
import More from "./More";
import Move from "./Move";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import Button from "../../common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import Bank from "./pay-components/Bank";
import Cash from "./pay-components/Cash";
import Debit from "./pay-components/Debit";

const idProduct = 4;

const Default = ({ history, setBalance, bannerId, flux, setFlux, checkToken, canSoliciteMore }) => {
  const [customerBalance, setCustomerBalance] = useState({
    creditLimit: 0,
    creditLimitUsed: 0,
    liquidateAmount: 0,
  });
  const [contract, setContract] = useState("");
  // const [accStatement, setAccStatement] = useState(null)
  const [serverError, setServerError] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [loadingAcc, setLoadingAcc] = useState(true)
  //const [open, setOpen] = useState(false)
  const [, setVidSrc] = useState("/img/banners/Desktop/Video1.mp4");
  const [, setVidMobSrc] = useState("/img/banners/Mobile/Video1.mp4");
  const [loanNumber, setLoanNumber] = useState(0);
  const [showPaymentData, setShowPaymentData] = useState(false);
  const [paymentTab, setPaymentTab] = useState(1);
  const { trackPageView } = useMatomo();

  const getData = async (user) => {
    setLoading(true);
    // Here goes post to get user debt instead of session storage
    let response = await getToken();
    if (!response) return setServerError(true);
    let {
      data: { token: validToken },
    } = response;
    const data = {
      idProduct,
      idCustomer: user.customerId,
    };

    getStatus(idProduct, user.customerId, false, validToken).then((res) => {
      if (res.status === 200) {
        if (res.data.idStatus === 6) {
          if (res.data.idSubStatus === 15) return history.push("/repeated/application/pre-approved");
        }
        if (res.data.idStatus === 1) {
          if (res.data.idSubStatus === 184) return history.push("/registration");
          if (res.data.idSubStatus === 196) return history.push("/pre-approved");
          if (res.data.idSubStatus === 203) return history.push("/pre-approved");
          if (res.data.idSubStatus === 206) return history.push("/application-review");
          if (res.data.idSubStatus === 217) return history.push("/dashboard/id");
          if (res.data.idSubStatus === 270) return history.push("/loan-review");
          if (res.data.idSubStatus === 271) return history.push("/loan-review");
          if (res.data.idSubStatus === 273) return history.push("/loan-review");

          return history.push("/registration");
        }
        if (res.data.idStatus === 7) {
          setLoading(false);
        }
        if (res.data.idStatus === 8) return history.push("/dashboard");
        if (res.data.idStatus === 4) {
          if (res.data.idSubStatus === 204) return history.push("/rejected");

          return history.push("/denied");
        }
        getCustomerBalance(data, validToken) //
          .then((customerBalance) => {
            setLoanNumber(customerBalance.data.reference);
            if (customerBalance.status === 200) {
              if (customerBalance.data.creditLimitUsed === 0) return history.push("/dashboard");
              setCustomerBalance(customerBalance.data);
              setBalance(customerBalance.data);
              sessionStorage.setItem("balance", JSON.stringify(customerBalance.data));
              trackPageView({
                documentTitle: "Loan Dashboard",
                href: "https://dfi.world",
              });

              return setLoading(false);
            }
            setServerError(true);
            setLoading(false);
          })
          .catch((err) => {
            setServerError(true);
            setLoading(false);
          });

        getFilledContract({ idProduct, idCustomer: user.customerId }, validToken)
          .then((res) => {
            if (res.status === 200) setContract(res.data.document);
          })
          .catch((err) => console.log(err));

        // getAccountStatement(data, validToken)
        // .then(res => {
        //     if(res.data){
        //         setAccStatement(res.data.document)
        //         setLoadingAcc(false)
        //     }
        // })
        // .catch(err =>  console.log(err))

        //TAG MANAGER
        getAnalytics({ idCustomer: user.customerId, idProduct }, validToken).then((analytics) => {
          if (analytics.data) {
            const tagManagerArgs = {
              dataLayer: {
                event: "pageChange",
                page: {
                  url: "/dashboard/payment",
                  referrer: "/login",
                },
                client: {
                  hFN: analytics.data.hFn,
                  hLN: analytics.data.hLN,
                  hTN: analytics.data.hTN,
                  hMA: analytics.data.hMA,
                  dateOfBirth: analytics.data.dateOfBirth,
                  returningClient: analytics.data.returningClient,
                  identifiedBy: analytics.data.identifiedBy,
                  registeredBy: analytics.data.registeredBy,
                },
                loans: {
                  loansCount: analytics.data.loansCount,
                },
                lastest_loan: {
                  status: analytics.data.status,
                  id: analytics.data.id,
                  repaymentDate: analytics.data.repaymentDate,
                },
                application: {
                  id: analytics.data.application.id,
                },
              },
              dataLayerName: "dataLayer",
            };
            TagManager.dataLayer(tagManagerArgs);
          }
        });
        //TAG MANAGER
        return;
      }
      return history.push({
        pathname: "/error",
        state: { endpoint: "getStatus", status: res.status },
      });
    });
  };

  // useEffect(() => {
  //     if(bannerId !== 0){
  //         setVidSrc(process.env.PUBLIC_URL + `/img/banners/Desktop/Video${bannerId}.mp4`)
  //         setVidMobSrc(process.env.PUBLIC_URL + `/img/banners/Mobile/Video${bannerId}.mp4`)
  //     }
  // }, [bannerId])

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (user) {
      if (user.eMail === "demo@demo.com") {
        let dummyData = {
          creditLimit: 5000,
          creditLimitUsed: 2000,
          liquidateAmount: 1500,
          term: 3,
          frequency: 3,
          idFrequency: 3,
          curentInstallment: {
            idDeferral: 1,
            principalBalance: 500,
            interest: 250,
            paymentValue: 560,
            dueDate: new Date(),
          },
          paidAmount: 1000,
          installments: [
            { idDeferral: 1, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 2, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 3, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 4, dueDate: new Date(), paymentValue: 200 },
          ],
          payments: [
            { idDeferral: 1, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 2, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 3, dueDate: new Date(), paymentValue: 200 },
            { idDeferral: 4, dueDate: new Date(), paymentValue: 200 },
          ],
        };
        setCustomerBalance(dummyData);
        sessionStorage.setItem("balance", JSON.stringify(dummyData));
        return setBalance(dummyData);
      }
      getData(user);
    }
    console.log("USEEFFECT CALLED ");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full items-center flex-col">
        {
          // loading ||
          !customerBalance?.idCredit ? (
            <BallClipRotate loading className="h-48 w-48 self-center justify-self-center place-self-center" color="#A3CD3A" />
          ) : (
            <>
              {loanNumber !== 0 && (
                <div className="border border-green-light w-full p-4 flex items-center justify-between flex-col lg:flex-row gap-4">
                  <p className="lg:self-start self-center text-center text-xl">
                    <strong>Número de préstamo: </strong>
                    {loanNumber}
                  </p>
                  {contract.length > 0 ? (
                    <a href={`data:application/pdf;base64,${contract}`} download="contrato_VIVUS.pdf" className="lg:self-end text-blue-primary cursor-pointer font-bold hover:text-blue-light active:text-blue-dark">
                      Tu contrato
                    </a>
                  ) : (
                    <p className="text-[#4f4f4f] lg:self-end">Cargando contrato...</p>
                  )}
                </div>
              )}

              <div className="flex justify-around w-full flex-col lg:flex-row gap-6 bg-green-light p-4">
                <div className="flex flex-col gap-2 items-center text-center">
                  <p className="text-[#4f4f4f]">Inicio del préstamo</p>
                  <p className="font-bold text-xl text-green-primary">{momentEs(customerBalance?.curentInstallment.dueDate).subtract(customerBalance.term, "days").format("D/MMM/Y")}</p>
                </div>
                <div className="flex flex-col gap-2 items-center text-center">
                  <p className="text-[#4f4f4f]">Fin del préstamo</p>
                  <p className="font-bold text-xl text-green-primary">{momentEs(customerBalance?.curentInstallment.dueDate).format("D/MMM/Y")}</p>
                </div>
                <div className="flex flex-col gap-2 items-center text-center">
                  <p className="text-[#4f4f4f]">Monto total a pagar</p>
                  <p className="font-bold text-xl text-green-primary">{customerBalance?.curentInstallment?.paymentValue.toLocaleString("en-US", { style: "currency", currency: "USD" })} MXN</p>
                  <p>IVA incluído</p>
                </div>
              </div>
              {customerBalance.curentInstallment.penalties > 0 && (
                <div className="p-4 flex bg-orange-lighter text-orange-primary w-full">
                  <span>
                    {" "}
                    Realiza el pago de tu préstamo lo antes posible, cuentas con una penalidad de &nbsp; <span className="font-bold"> {customerBalance.curentInstallment.dueDays} </span> &nbsp;días, equivalente a &nbsp;<span className="font-bold">${customerBalance.curentInstallment.penalties}</span>.
                  </span>
                </div>
              )}
            </>
          )
        }
      </div>
      <div className="w-full flex gap-4 flex-col lg:flex-row">
        <Button
          style={{ color: "" }}
          onClick={() => {
            setFlux("pay");
            setShowPaymentData(true);
          }}
        >
          Pagar mi préstamo
        </Button>
        <Button
          className={` ${history.location.pathname === "/dashboard/extension" ? "bg-blue-primary hover:bg-blue-light active:bg-blue-dark" : "bg-blue-lighter text-blue-primary"}`}
          onClick={() => {
            history.push("/dashboard/extension#extensions");
            setTimeout(() => {
              const e = document.getElementById("extensions");
              e.scrollIntoView();
            }, 300);
          }}
        >
          Extensión
        </Button>
        {canSoliciteMore && (
          <button
            className={`border-2 border-green-dark flex-1 p-4 uppercase font-bold ${history.location.pathname === "/dashboard/additional-amount" ? "bg-green-dark text-white" : "bg-gray-50 text-green-dark"}`}
            onClick={() => {
              history.push("/dashboard/additional-amount");
            }}
          >
            Solicita más dinero
          </button>
        )}
      </div>
      <div className="w-full">
        <Switch>
          <Route exact path="/dashboard/payment"></Route>

          <Route exact path="/dashboard/extension">
            <Move setFlux={setFlux} loanNumber={loanNumber} />
          </Route>

          <Route exact path="/dashboard/additional-amount">
            <More />
          </Route>
        </Switch>
      </div>

      {showPaymentData && (
        <div
          className="fixed top-0 left-0 w-full h-full overflow-hidden z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setShowPaymentData(false);
            }
          }}
        >
          <div className="py-8 px-4 flex flex-col gap-4 relative bg-white w-full rounded-xl max-w-5xl mx-2 max-h-screen overflow-auto">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={(e) => {
                setShowPaymentData(false);
              }}
              width={24}
              className="absolute top-4 right-4 cursor-pointer"
            />
            <h4 className="text-xl font-bold text-green-primary">Métodos de pago</h4>
            <p className="font-bold">Detalles de pago</p>
            <div className="rounded-lg bg-green-light p-4 flex justify-around">
              <div className="flex flex-col gap-2">
                <span>Monto a pagar</span>
                <span className="text-green-primary text-xl font-bold">
                  {customerBalance?.curentInstallment?.paymentValue.toLocaleString("en-US", { style: "currency", currency: "USD" })} <small>IVA Incluido</small>
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <span>Vencimiento</span>
                <span className="text-green-primary text-xl font-bold">{momentEs(customerBalance?.curentInstallment.dueDate).format("D/MMM/Y")}</span>
              </div>
            </div>
            <div className="flex flex-col bg-white rounded-lg p-4">
              <div
                className={`flex justify-between cursor-pointer p-4 rounded-t-lg ${paymentTab === 1 ? "bg-[#f0f0f0]" : ""}`}
                onClick={() => {
                  setPaymentTab(paymentTab === 1 ? 0 : 1);
                }}
              >
                <span className="font-bold text-black-primary text-xl">Efectivo en tiendas</span>
                <div className="text-black-primary text-lg my-0 mr-4">{paymentTab === 1 ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
              </div>
              {paymentTab === 1 && (
                <div className="bg-[#f0f0f0] rounded-b-lg">
                  <Cash balance={customerBalance} />
                </div>
              )}
              <div
                className={`flex justify-between cursor-pointer p-4 rounded-t-lg ${paymentTab === 2 ? "bg-[#f0f0f0]" : ""}`}
                onClick={() => {
                  setPaymentTab(paymentTab === 2 ? 0 : 2);
                }}
              >
                <span className="font-bold text-black-primary text-xl">Transferencia</span>
                <div className="text-black-primary text-lg my-0 mr-4">{paymentTab === 2 ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
              </div>
              {paymentTab === 2 && (
                <div className="bg-[#f0f0f0] rounded-b-lg">
                  <Bank balance={customerBalance} />
                </div>
              )}
              <div
                className={`flex justify-between cursor-pointer p-4 rounded-t-lg ${paymentTab === 3 ? "bg-[#f0f0f0]" : ""}`}
                onClick={() => {
                  setPaymentTab(paymentTab === 3 ? 0 : 3);
                }}
              >
                <span className="font-bold text-black-primary text-xl">Tarjeta de Débito</span>
                <div className="text-black-primary text-lg my-0 mr-4">{paymentTab === 3 ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
              </div>
              {paymentTab === 3 && (
                <div className="bg-[#f0f0f0] rounded-b-lg">
                  <Debit balance={customerBalance} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Default);
