import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TagManager from "react-gtm-module";
import LinkButton from "../common/LinkButton";
import { getAnalytics, getToken } from "../../services/api";

const AFFILIATES = [{ name: "Credy", img: "/img/logo-credy.svg", url: "https://tracking.adcredy.com/SH17g" }];
const idProduct = 4;

export const NotApproved = () => {
  const [affiliate, setAffiliate] = useState(AFFILIATES[0]);
  const [isReturning, setIsReturning] = useState(false);

  useEffect(() => {
    const t = async () => {
      const loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
      let response = await getToken();
      const validToken = response.data.token;

      if (loggedUser.customerId) {
        getAnalytics({ idCustomer: loggedUser.customerId, idProduct }, validToken).then((analytics) => {
          if (analytics.data) {
            setIsReturning(analytics.data.returningClient === "RETURNING");
          }
        });
      }
    };
    t();
  }, []);

  //TAG MANAGER
  const tagManagerArgs = {
    dataLayer: {
      event: "pageChange",
      page: {
        url: "/rejected",
        referrer: sessionStorage.getItem("utm") || "/",
      },
    },
    dataLayerName: "dataLayer",
  };
  TagManager.dataLayer(tagManagerArgs);

  useEffect(() => {
    setAffiliate(AFFILIATES[0]);
  }, []);

  //TAG MANAGER
  return (
    <>
      <div className="bg-white relative w-full flex justify-center flex-1">
        <div className="container flex flex-col gap-8 px-4 py-12 self-center">
          <div className="w-full flex flex-col gap-2 2xl:w-2/3">
            <h1 className="text-3xl font-extra-bold text-green-primary">Disculpa, lamentablemente no podemos ofrecerte un préstamo en este momento.</h1>
          </div>
          <div className="w-full flex flex-col gap-4 rounded-lg bg-[#f0f0f0] p-4">
            <div className="flex flex-col gap-2">
              <h3 className="text-xl text-black-primary font-bold">Razones comunes por las cuales un crédito es rechazado:</h3>
              <ul>
                <li className="text-md flex gap-4 items-center">
                  <FontAwesomeIcon icon={faTimes} />
                  <span>No se pudo consultar tu historial crediticio.</span>
                </li>
                <li className="text-md flex gap-4 items-center">
                  <FontAwesomeIcon icon={faTimes} />
                  <span>No se pudo verificar tu identidad.</span>
                </li>
                <li className="text-md flex gap-4 items-center">
                  <FontAwesomeIcon icon={faTimes} />
                  <span>Tu nivel de endeudamiento es actualmente alto.</span>
                </li>
                <li className="text-md flex gap-4 items-center">
                  <FontAwesomeIcon icon={faTimes} />
                  <span>Tienes adeudos pendientes con otras compañías.</span>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="text-xl text-black-primary font-bold">Cosas que puedes hacer:</h3>
              <p>
                Es fundamental que revises tu historial crediticio. Verifica que tus <strong>datos personales</strong> sean correctos, especialmente tu <strong>RFC</strong> y <strong>domicilio</strong>. Asegúrate que todos los datos específicos sobre alguna deuda en el pasado estén actualizados
              </p>
              {!isReturning && (
                <div className="py-8 px-4 gap-2 flex flex-col justify-center items-center text-center border-2 rounded-md border-blue-600  lg:w-1/3 w-full bg-white">
                  <img src={affiliate.img} alt="Logo Afiliado" width={affiliate.name === "Credy" ? 150 : 250} />
                  <h4 className="text-xl text-blue-600">¡No te preocupes!</h4>
                  <p>Aún puedes obtener el préstamo que necesitas</p>
                  <LinkButton href={affiliate.url} className="bg-blue-600 hover:bg-blue-400 active:bg-blue-800 cursor-pointer rounded-md p-4 w-32 text-center items-center justify-center font-bold uppercase">
                    Aplicar
                  </LinkButton>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <h3 className="text-xl text-black-primary font-bold">Nos mantendremos en contacto</h3>
              <p>
                Nos encantaría poder otorgarte un préstamo en el futuro. Si crees que esta respuesta es incorrecta, si tu situación cambia o sospechas que existe algún error en tu historial de crédito que puede ser aclarado con la institución que te reporta, por favor contáctanos nuevamente y te
                atenderemos con gusto.
              </p>
              <strong className="text-xl">Mucha suerte. El equipo de doctorpeso.com.mx</strong>
              {/* {process.env.REACT_APP_SITE === "mivivus" && (
                <a href="https://alprestamo.mx/?utm_medium=rec&utm_source=vivus01" target="_blank">
                  <img src={"/img/banner-rejected.jpeg"} alt="Banner afiliado" className="w-full lg:w-1/4 my-4" />
                </a>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
