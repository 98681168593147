import React, { useState, useEffect } from "react";
import { getToken, getBankPaymentReference } from "../../../../../services/api";

const idProduct = 4;

const BankOption = () => {
  const [serverError, setServerError] = useState(false);
  const [bank0, setBank0] = useState({});

  const bankData = async (user, idBank, validToken) => {
    const data = {
      idProduct,
      idCustomer: user.customerId,
      idBank,
    };
    if (idBank === 3) {
      getBankPaymentReference(data, validToken)
        .then((res) => setBank0(res.data))
        .catch((err) => setServerError(true));
    }
  };

  const getData = async (user) => {
    // Here goes post to get user debt instead of session storage
    let response = await getToken();
    if (!response) return setServerError(true);
    let validToken = response.data.token;
    bankData(user, 3, validToken); //BBVA
    bankData(user, 10, validToken); //CI
  };

  useEffect(() => {
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (demoUser || user.eMail === "demo@demo.com") {
      setBank0({
        account: "25478154",
        paymentReference: "12354874561237862",
      });
      return;
    }
    if (user) {
      getData(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-4 py-8 px-4">
      <h4 className="text-xl font-bold text-black-primary">Pago por SPEI</h4>
      <div className="flex flex-col lg:flex-row space-between w-full">
        <ol className="text-left list-decimal ml-4">
          <li>Ingresar al portal de banca electrónica de tu banco.</li>
          <li>Selecciona la opción "Transferencias" o "Pagos".</li>
          <li>
            Ingresa tu CLABE personalizada: <strong>{bank0.paymentReference}</strong>.
          </li>
          <li>Ingresa el monto total a pagar de tu préstamo.</li>
          <li>Confirma el pago y ¡Listo!</li>
        </ol>
        <img src={"/img/spei.png"} alt="SPEI imagen" className="w-full lg:w-64" />
      </div>

      {serverError && <span>*Error en el servidor</span>}
    </div>
  );
};

export default BankOption;
