import React, { useState, useEffect } from "react";
import "./common.scss";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { momentEs } from "../../services/moment";
import LinkButton from "./LinkButton";
import { getAnalytics, getToken } from "../../services/api";
import faBars from "../../assets/svg/menu.svg";
import faTimes from "../../assets/svg/close.svg";

const idProduct = 4;

const NavBar = ({ isLogged, location, setLocation }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showProfile, setShowProfile] = useState(true);
  const [, setTempScreen] = useState(null);
  const [loc, setLoc] = useState("");

  useEffect(() => {
    setLoc(document.location.pathname);
  }, [document.location.href]);

  useEffect(() => {
    const f = async () => {
      let response = await getToken();
      const validToken = response.data.token;
      const idCustomer = JSON.parse(sessionStorage.loggedUser).customerId;
      let {
        data: { returningClient },
      } = await getAnalytics({ idProduct, idCustomer }, validToken);
      console.log(returningClient);
      if (returningClient === "RETURNING") {
        setShowProfile(true);
      } else {
        const ssCurrentStatus = sessionStorage.getItem("currentStatus");
        if (ssCurrentStatus) {
          const {
            data: { idStatus },
          } = JSON.parse(ssCurrentStatus);

          if (!idStatus || idStatus === 1 || idStatus === 8 || idStatus === 4) {
            setShowProfile(false);
          } else {
            setShowProfile(true);
          }
        } else {
          setShowProfile(false);
        }
      }
    };
    f();
  }, [sessionStorage.currentStatus]);
  const logo = "/img/navbar/logo-doctor-peso.svg";
  useEffect(() => {
    let startDate = momentEs("01/04/2020", "DD/MM/YYYY");
    let endDate = momentEs("30/04/2020", "DD/MM/YYYY");
    if (momentEs().isBetween(startDate, endDate)) return setTempScreen(true);
    return setTempScreen(false);
  }, []);

  return (
    <nav className={`flex justify-center z-20 w-full  ${location === "home" ? "absolute top-0 bg-transparent" : "bg-white relative"}`}>
      <div className="flex w-full justify-between items-center container md:py-8 p-4">
        <Link className="w-3/12 md:w-28" onClick={() => setLocation("home")} to="/">
          <img className="w-full" src={logo} alt="Doctor Peso Logo" />
        </Link>

        <ul className="justify-end flex-grow text-black gap-x-6 pr-6 hidden md:flex">
          <li>
            <Link className={`hover:text-green-primary text-sm transition-all ${location === "works" ? "text-green-primary" : "text-black-primary"}`} onClick={() => setLocation("works")} to="/como-funciona">
              ¿Cómo funciona? {isLogged}
            </Link>
          </li>
          {/* {process.env.REACT_APP_SITE !== "mivivus" && (
            <li>
              <Link className={`hover:text-green-dark text-sm transition-all ${location === "blog" ? "text-green-primary" : "text-black-primary"}`} onClick={() => setLocation("blog")} to="/blog">
                Blog
              </Link>
            </li>
          )} */}
          <li>
            <Link className={`hover:text-green-primary text-sm transition-all ${location === "us" ? "text-green-primary" : "text-black-primary"}`} onClick={() => setLocation("us")} to="/us">
              Sobre Nosotros
            </Link>
          </li>

          {isLogged && showProfile && (
            <li>
              <Link className={`hover:text-green-primary text-sm transition-all ${location === "profile" ? "text-green-primary" : "text-black-primary"}`} onClick={() => setLocation("profile")} to="/perfil">
                Mis datos
              </Link>
            </li>
          )}
          {isLogged && (
            <li>
              <Link
                className={`hover:text-green-primary text-sm transition-all text-red`}
                onClick={() => {
                  setShowMenu(!showMenu);
                  sessionStorage.clear();
                  cookie.remove("token");
                }}
                to="/login"
              >
                Cerrar sesión
              </Link>
            </li>
          )}
        </ul>

        {!isLogged && (
          <LinkButton
            onClick={() => {
              setLocation("app");
              setShowMenu(false);
            }}
            type="secondary"
            className={"!p-2 !lg:px-4 !lg:py-3"}
            to="/login"
          >
            ¿Ya eres cliente?
          </LinkButton>
        )}
        <div className="relative md:hidden bg-white">
          <div className="text-black-primary text-xl">
            <img className={`transition-all`} onClick={() => setShowMenu(!showMenu)} src={showMenu ? faTimes : faBars} />
          </div>
          <ul className={`top-10 -right-4 p-4 z-30 absolute w-screen shadow-md bg-white transition-all ${showMenu ? "opacity-1" : "opacity-0 pointer-events-none "}`}>
            <li className="p-4">
              <Link
                onClick={() => {
                  setLocation("works");
                  setShowMenu(false);
                }}
                className="text-black-primary hover:text-green-primary transition-all"
                to="/como-funciona"
              >
                ¿Cómo funciona?
              </Link>
            </li>
            <li className="p-4">
              <Link
                onClick={() => {
                  setLocation("us");
                  setShowMenu(false);
                }}
                className="text-black-primary hover:text-green-primary transition-all"
                to="/us"
              >
                Sobre nosotros
              </Link>
            </li>

            {isLogged && showProfile && (
              <li className="p-4">
                <Link
                  onClick={() => {
                    setLocation("profile");
                    setShowMenu(false);
                  }}
                  to="/perfil"
                  className="text-black-primary hover:text-green-primary transition-all"
                >
                  Mis datos
                </Link>
              </li>
            )}
            {isLogged && (
              <li className="p-4">
                <Link
                  onClick={() => {
                    sessionStorage.clear();
                    cookie.remove("token");
                    setLocation("app");
                    setShowMenu(!showMenu);
                  }}
                  to="/login"
                  className="text-black-primary hover:text-green-primary transition-all"
                >
                  Cerrar sesión
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
