import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export const AccordionFaq = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="py-0 cursor-pointer">
      <div onClick={() => setOpen(!open)} className={`flex w-full justify-between  rounded-t-lg text-lg py-2 pl-4 ${open ? "bg-[#f0f0f0] cursor-pointer" : " "}`}>
        <div className="font-bold text-xl">{question}</div>
        <div className="text-black-primary text-lg my-0 mr-4">{open ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
      </div>
      <div className={`h-auto transition-all text-md bg-[#f0f0f0] rounded-b-lg p-4 text-left ${open ? "" : "hidden"}`}>{answer}</div>
    </div>
  );
};
