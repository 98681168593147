import { getCustomerByMail, getRegistration, getStatus, getToken, saveSingleUTM, sendCodeByNewSMS, sendCodeBySMS, sendCodeByWA } from "../services/api";
import { useLocation } from "./location";

const idProduct = 4;

export const useLogin = () => {
  const { location, ip } = useLocation();

  const sendSMS = async () => {
    const loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    const data = {
      idProduct,
      idCustomer: loggedUser.customerId,
      isNIP: true,
    };
    let smsNewData = {
      newMobile: loggedUser.mobile,
      userAgent: navigator.userAgent,
      remoteIp: ip,
      coordinates: location,
      isNIP: false,
      idCustomer: loggedUser.idCustomer,
      idProduct,
    };

    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;

    const askPhoneRepeater = sessionStorage.getItem("ask_phone_repeater");
    if (askPhoneRepeater) {
      return sendCodeByNewSMS(smsNewData, validToken)
        .then(() => console.log("SMS Sent"))
        .catch((err) => console.log(err));
    } else {
      //sendCodeByWA(data, validToken);
      return sendCodeBySMS(data, validToken)
        .then(() => console.log("Message sent"))
        .catch((err) => console.log(err));
    }
  };

  const checkStatus = async (idCustomer, token, ip, email, history, cleanLogin, setServerError = () => null, setPassError = () => null, setLoading = () => null) => {
    let dataWithStatus = {
      data: JSON.parse(sessionStorage.getItem("customer-register")),
      step: 0,
    };

    let utm = sessionStorage.getItem("utm");

    saveSingleUTM(
      {
        customerOrigin: document.URL,
        utm,
        userAgent: navigator.userAgent,
        clientIP: ip,
        idCustomer,
        idProduct,
      },
      token
    );

    return getStatus(idProduct, idCustomer, false, token) //false is for isNIP
      .then(async (res) => {
        const { data } = res;
        let directStep = 1;
        if (res.status === 200) {
          if (data.idStatus === 1) {
            return getCustomerByMail(idProduct, email, token)
              .then((res) => {
                getRegistration(idProduct, idCustomer, email, token).then((res) => {
                  sessionStorage.setItem("nombre-usuario", res.data.firstName);
                  sessionStorage.setItem("segundo-nombre-usuario", res.data.secondName);
                  sessionStorage.setItem("apellido-usuario", res.data.firstLastName);
                  sessionStorage.setItem("apellido-mat-usuario", res.data.secondLastName);
                });

                if (data.idSubStatus === 180) {
                  dataWithStatus.step = 1;
                  directStep = 1;
                }
                if (data.idSubStatus === 181) {
                  dataWithStatus.step = 2;
                  directStep = 2;
                }
                if (data.idSubStatus === 182) {
                  dataWithStatus.step = 3;
                  directStep = 3;
                }
                if (data.idSubStatus === 183) {
                  dataWithStatus.step = 4;
                  directStep = 4;
                }
                if (data.idSubStatus === 184) {
                  dataWithStatus.step = 4;
                  directStep = 4;
                }
                if (data.idSubStatus === 185) {
                  dataWithStatus.step = 3;
                  directStep = 3;
                }
                if (data.idSubStatus === 195) {
                  dataWithStatus.step = 5;
                  directStep = 5;
                }
                if (data.idSubStatus === 1) return history.push("/registration");
                if (data.idSubStatus === 196) return history.push("/pre-approved");
                if (data.idSubStatus === 203) return history.push("/pre-approved");
                if (data.idSubStatus === 206) return history.push("/application-review");
                if (data.idSubStatus === 217) return history.push("/dashboard/id");
                if (data.idSubStatus === 247) return history.push("/dashboard/id");
                if (data.idSubStatus === 218) return history.push("/application-complete");
                if (data.idSubStatus === 219) return history.push("/application-complete");
                if (data.idSubStatus === 248) return history.push("/application-complete");
                if (data.idSubStatus === 254) return history.push("/rejected");

                if (data.idSubStatus === 255) return history.push("/identity-validated");
                if (data.idSubStatus === 257) return history.push("/identity-validated");
                if (data.idSubStatus === 270) return history.push("/loan-review");
                if (data.idSubStatus === 271) return history.push("/loan-review");
                if (data.idSubStatus === 273) return history.push("/loan-review");

                if (cleanLogin) {
                  sessionStorage.setItem(
                    "empty-customer",
                    JSON.stringify({
                      idCustomer,
                      email,
                      mobile: res.data.mobile,
                    })
                  );
                  sessionStorage.setItem("customer-direct-step", JSON.stringify(directStep));
                  if (directStep === 1) return history.push("/registration/personal-details");
                  if (directStep === 2) return history.push("/registration/employment-details");
                  if (directStep === 3 || directStep === 4) {
                    sendSMS();
                    return history.push("/registration/nip-bureau");
                  }
                  if (directStep === 5) return history.push("/registration-complete");
                  // return history.push('/registration')
                }
                if (sessionStorage.getItem("recoveryEmail")) {
                  sessionStorage.removeItem("recoveryEmail");
                  sessionStorage.setItem(
                    "empty-customer",
                    JSON.stringify({
                      idCustomer,
                      email,
                      mobile: res.data.mobile,
                    })
                  );
                  sessionStorage.setItem("customer-direct-step", JSON.stringify(directStep));
                  if (directStep === 1) return history.push("/registration/personal-details");
                  if (directStep === 2) return history.push("/registration/employment-details");
                  if (directStep === 3 || directStep === 4) {
                    sendSMS();
                    return history.push("/registration/nip-bureau");
                  }
                  if (directStep === 5) return history.push("/registration-complete");
                  // return history.push('/registration')
                }
                sessionStorage.removeItem("recoveryEmail");
                sessionStorage.setItem("customer-logged", JSON.stringify(dataWithStatus));
                if (dataWithStatus.step === 1) return history.push("/registration/personal-details");
                if (dataWithStatus.step === 2) return history.push("/registration/employment-details");
                if (dataWithStatus.step === 3 || dataWithStatus.step === 4) {
                  sendSMS();
                  return history.push("/registration/nip-bureau");
                }
                if (dataWithStatus.step === 5) return history.push("/registration-complete");
                // return history.push('/registration')
              })
              .catch((err) => setServerError(true));
          }
          if (data.idStatus === 4) {
            sessionStorage.setItem(
              "empty-customer",
              JSON.stringify({
                idCustomer,
                email,
                mobile: res.data.mobile,
                penaltyDays: res.data.penaltyDays,
              })
            );
            sessionStorage.setItem("APP", "no");
            return history.push("/denied");
          }
          if (data.idStatus === 6) {
            sessionStorage.setItem("empty-customer", JSON.stringify(res.data));
            sessionStorage.setItem("APP", "yes");
            return history.push("/dashboard/general");
          }
          if (data.idStatus === 7) {
            sessionStorage.setItem("empty-customer", JSON.stringify(res.data));
            sessionStorage.setItem("APP", "yes");
            return history.push("/dashboard/general");
          }
          if (data.idStatus === 8) {
            sessionStorage.setItem("empty-customer", JSON.stringify(res.data));
            sessionStorage.setItem("APP", "yes");
            return history.push("/dashboard");
          }
          setLoading(false);
          setPassError(false);
          return history.push("/dashboard/general");
        } else {
          setLoading(false);
          return setServerError(true);
        }
      })
      .catch((err) => setServerError(true));
  };
  return { checkStatus };
};
