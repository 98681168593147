import React from "react";

export const HowToPay = () => {
  return (
    <div className="flex justify-center items-center flex-1 flex-col ">
      <div className="container px-4 py-12 flex gap-4 flex-col">
        <div className="flex flex-col gap-2 w--full lg:w-2/3">
          <h1 className="text-3xl font-extra-bold">¿Cómo pagar mi préstamo?</h1>
          <ol className="pb-4">
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['1'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              Ten a la mano tu número de préstamo. Puedes consultarlo en tu perfil Doctor Peso.
            </li>
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['2'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              Una vez en tu perfil Doctor Peso, obtén el número de referencia de pago correspondiente método de pago de tu preferencia. *Recuerda que las referencias cambian por cada préstamo que solicites.
            </li>
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['3'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              Realiza tu pago con alguna de las siguientes opciones.
            </li>
          </ol>
          <h2 className="text-2xl font-bold">Oxxo</h2>
          <ol className="pb-4">
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['1'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              Solo debes presentar tu número de referencia en cualquier tienda OXXO, pagar en efectivo e indicar que lo harás a través de la modalidad OXXO PAY. Una vez realizado, tu pago se verá reflejado en 1 minuto.{" "}
            </li>
          </ol>

          <h2 className="text-2xl font-bold">SPEI</h2>

          <div className="text-lg">
            <p>Puedes pagar con tu referencia SPEI personalizada, encontrándola desde tu perfil de Doctor Peso o bien en tus instrucciones de pago y puedes hacer transferencia desde cualquier aplicación de banco.</p>

            <ol className="pb-4">
              <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['1'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
                <p>Ingresa a tu banca móvil y dirígete a la opción “transferencias”</p>
              </li>
              <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['2'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
                Coloca tu referencia SPEI personalizada
              </li>
              <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['3'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
                Ingresa el monto a pagar
              </li>
              <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['4'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
                En concepto coloca tu número de préstamo
              </li>
              <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['5'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
                Si te pide un beneficiario coloca: INTEGRATED MANAGEMENT SERVICES. El banco destino es de STP.
              </li>
            </ol>
          </div>

          <h2 className="text-2xl font-bold">Tarjeta de débito</h2>
          <ol className="pb-4">
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['1'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              <p>
                Ingresa a tu perfil Doctor Peso y selecciona “Pagar mi préstamo” &gt; “Tarjeta de débito”, o ingresa directamente a&nbsp;
                <a className="font-bold text-blue-primary" href="https://4finance.mit.com.mx/vivWeb/">
                  este enlace
                </a>
                .
              </p>
            </li>
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['2'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              Ingresa tu número de préstamo.
            </li>
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['3'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              Ingresa tus datos de pago.
            </li>
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['4'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              Guarda tu comprobante de pago. Tu pago se verá reflejado en el transcurso de 24 horas hábiles.
            </li>
          </ol>
          <br />
        </div>
      </div>
    </div>
  );
};
