import React, { useState, useEffect } from "react";
import { Obtain } from "./Obtain";
import { Restructure } from "./Restructure";
import { AdditionalAmount } from "./AdditionalAmount";
import { FAQ } from "./FAQ";
import "./works.scss";
import TagManager from "react-gtm-module";
import { HowToPay } from "./HowToPay";
import { useLocation } from "react-router-dom";

const tagManagerArgs = {
  dataLayer: {
    event: "pageChange",
    page: {
      url: "/como-funciona",
      referrer: sessionStorage.getItem("utm") || "/",
    },
  },
  dataLayerName: "dataLayer",
};

export const HowItWorks = () => {
  TagManager.dataLayer(tagManagerArgs);
  const [section, setSection] = useState(1);
  const location = useLocation();
  useEffect(() => {
    setSection(parseInt(new URLSearchParams(location.search).get("section") || 1));
  }, [location]);

  return (
    <div className="flex flex-col items-center flex-1">
      <div className="flex justify-center w-full bg-gradient-to-b from-orange-primary to-orange-yellow">
        <div className="flex container flex-col lg:flex-row gap-4 justify-start py-8 px-4 bg-gradient-to-b from-orange-primary to-orange-yellow text-black-primary">
          <div className={`w-64 px-4 py-3 transition-all rounded-lg hover:text-white flex cursor-pointer items-center ${section === 1 ? "bg-black-primary text-orange-primary" : null}`} onClick={() => setSection(1)}>
            ¿Cómo puedo obtener un préstamo?
          </div>
          <div className={`w-64 px-4 py-3 transition-all rounded-lg hover:text-white flex cursor-pointer items-center ${section === 2 ? "bg-black-primary text-orange-primary" : null}`} onClick={() => setSection(2)}>
            ¿Cómo reestructuro el plazo de mi préstamo?
          </div>
          <div className={`w-64 px-4 py-3 transition-all rounded-lg hover:text-white flex cursor-pointer items-center ${section === 3 ? "bg-black-primary text-orange-primary" : null}`} onClick={() => setSection(3)}>
            ¿Cómo pagar mi préstamo?
          </div>
          <div className={`w-64 px-4 py-3 transition-all rounded-lg hover:text-white flex cursor-pointer items-center ${section === 4 ? "bg-black-primary text-orange-primary" : null}`} onClick={() => setSection(4)}>
            Preguntas frecuentes
          </div>
        </div>
      </div>
      {section === 1 ? <Obtain /> : section === 2 ? <Restructure /> : section === 3 ? <HowToPay /> : section === 4 ? <FAQ /> : <Obtain />}
    </div>
  );
};
