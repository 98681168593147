import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import cookie from "react-cookies";
import { getToken, recoverPassword, getCustomerByMail, sendCodeBySMS, sendCodeByWA } from "../../services/api";
import { BallBeat } from "react-pure-loaders";
import Button from "../common/Button";
import svgMail from "../../assets/svg/mail.svg";
import svgSMS from "../../assets/svg/sms.svg";

const idProduct = 4;

const NewForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingCel, setLoadingCel] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [cellError, setCellError] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [userMail, setUserMail] = useState("");

  const goSMS = async () => {
    setLoadingCel(true);
    setNotFound(false);
    setCellError(false);
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;
    let email = sessionStorage.getItem("recoveryEmail");
    setUserMail(email);
    getCustomerByMail(idProduct, email, validToken).then((res) => {
      if (res.data) {
        sessionStorage.setItem("mobile", res.data.mobile);
        sessionStorage.setItem("idCustomer", res.data.customerId);
        let data = {
          idProduct,
          idCustomer: res.data.customerId,
          isNIP: false,
        };
        //sendCodeByWA(data, validToken);
        return props.history.push("/confirmacion-sms");
        //return sendCodeBySMS(data, validToken).then((res) => props.history.push("/confirmacion-sms"));
      }
      if (res.response.status === 404) {
        setNotFound(true);
        setLoadingCel(false);
        return;
      }
      setCellError(true);
      setLoadingCel(false);
    });
  };

  const goEmail = async () => {
    setLoading(true);
    setNotFound(false);
    setEmailError(false);
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;
    let email = sessionStorage.getItem("recoveryEmail");
    setUserMail(email);
    const data = {
      eMail: email,
      idProduct,
    };
    recoverPassword(data, validToken).then((res) => {
      let { data } = res;
      if (data && data.codigo === "200") {
        setEmailError(false);
        return props.history.push("/confirmacion-email");
      }
      if (res.response.status === 404) {
        setNotFound(true);
        setLoading(false);
        return;
      }
      setEmailError(true);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem("loggedUser") && cookie.load("token")) props.history.push("/dashboard/initial");
  }, []);

  return (
    <div className="bg-white relative w-full flex justify-center items-start flex-1">
      <div className="container flex flex-col gap-4 p-4 ">
        <div className="flex flex-col gap-4 py-4 max-w-4xl self-start">
          <div className="w-full flex flex-col gap-2">
            <h1 className="text-3xl font-extra-bold text-black-primary">Recupera tu acceso</h1>
            <p>Selecciona el mecanismo mediante el cual deseas recuperar tu cuenta.</p>
          </div>

          <div className="w-full flex flex-col gap-4 rounded-lg bg-[#f0f0f0] p-8">
            <div className="flex flex-col lg:flex-row gap-4 justify-between">
              <div className="rounded-lg border-2 border-[#e0e0e0] px-8 py-12 flex flex-col gap-2 items-center justify-center w-full">
                <img src={svgMail} alt="mail" width={60} />
                <p className="font-bold text-center text-black-primary text-xl">Por mail</p>
                <p className="text-center">Vamos a enviarte un mail con el código de validación</p>
                <Button onClick={goEmail}>{loading ? <BallBeat color={"#fff"} loading /> : "Mail"}</Button>
              </div>

              <div className="rounded-lg border-2 border-[#e0e0e0] p-8 py-12 flex flex-col gap-2 items-center justify-center w-full">
                <img src={svgSMS} alt="mail" width={60} />
                <p className="font-bold text-center text-black-primary text-xl">Por SMS a tu celular</p>
                <p className="text-center">Vamos a enviarte un SMS con el código de validación</p>
                <Button onClick={goSMS}>{loadingCel ? <BallBeat color={"#fff"} loading /> : "SMS"}</Button>
              </div>
            </div>
            {cellError || emailError ? <p className="text-red">Ocurrió un problema, intenta nuevamente</p> : null}
            {notFound ? <p className="text-red">No hay ningún usuario asociado a {userMail}</p> : null}
            <Link to="/recupera" className="text-blue-primary hover:text-blue-light active:text-blue-dark font-bold transition-all">
              Regresar
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NewForgotPassword);
