import React, { useState } from "react";
import { checkCode, getToken, changeCustomerEmail, changeCustomerMobile } from "../../../../services/api";
import { BallClipRotate } from "react-pure-loaders";
import Button from "../../../common/Button";

const idProduct = 4;

const CheckCode = ({ history, email, setValidEmail, setSection, number, setValidNumber }) => {
  const [code, setCode] = useState("");
  const [codeErr, setCodeErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notChanged, setNotChanged] = useState(false);

  const validateCode = async () => {
    if (!code || code.length < 4) return setCodeErr(true);
    setCodeErr(false);
    setNotChanged(false);
    let response = await getToken();
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (response) {
      setLoading(true);
      let validToken = response.data.token;
      let coords = sessionStorage.getItem("coords");
      if (!coords) coords = "Location blocked";
      let myIp = sessionStorage.getItem("ip");
      if (!myIp) myIp = "192.168.1.254";
      if (email) {
        let data = {
          code,
          isNIP: false,
          userAgent: navigator.userAgent,
          clientIP: myIp,
          coordinates: coords,
          idCustomer: user.customerId,
          idProduct,
        };
        checkCode(data, validToken).then((res) => {
          if (res.status === 200) {
            let dataChange = {
              isNIP: false,
              userAgent: navigator.userAgent,
              remoteIp: myIp,
              coordinates: coords,
              idCustomer: user.customerId,
              idProduct,
            };
            return changeCustomerEmail({ newEMail: email, newMobile: "", ...dataChange }, validToken).then((changed) => {
              if (changed.status === 200) {
                let session = JSON.parse(sessionStorage.getItem("loggedUser"));
                session.eMail = email;
                sessionStorage.setItem("loggedUser", JSON.stringify(session));
                setSuccess(true);
                setLoading(false);
              }
              setNotChanged(true);
              setLoading(false);
            });
          }
          setCodeErr(true);
          setLoading(false);
        });
      }
      if (number) {
        let data = {
          code,
          isNIP: false,
          userAgent: navigator.userAgent,
          clientIP: myIp,
          coordinates: coords,
          idCustomer: user.customerId,
          idProduct,
        };
        checkCode(data, validToken).then((res) => {
          if (res.status === 200) {
            let dataChange = {
              isNIP: false,
              userAgent: navigator.userAgent,
              remoteIp: myIp,
              coordinates: coords,
              idCustomer: user.customerId,
              idProduct,
            };
            return changeCustomerMobile({ newEMail: "", newMobile: number, ...dataChange }, validToken).then((changed) => {
              if (changed.status === 200) {
                let session = JSON.parse(sessionStorage.getItem("loggedUser"));
                session.mobile = number;
                sessionStorage.setItem("loggedUser", JSON.stringify(session));
                setSuccess(true);
                setLoading(false);
              }
              setNotChanged(true);
              setLoading(false);
            });
          }
          setCodeErr(true);
          setLoading(false);
        });
      }
    }
  };

  return (
    <div>
      {success ? (
        <div className="flex flex-col gap-4">
          <h3 className="text-2xl">{number ? "Número" : "Correo"} actualizado correctamente</h3>
          <Button className={`lg:w-96 text-center`} onClick={() => setSection("home")}>
            Continuar
          </Button>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <h3 className="text-2xl">{number ? "Cambiar número celular" : "Cambiar correo electrónico"}</h3>
          <p>
            <strong>{number ? number : email}</strong>
          </p>
          <div className="text-green-primary underline" onClick={number ? () => setValidNumber(false) : () => setValidEmail(false)}>
            <p>{number ? "¿Número celular incorrecto?" : "¿Correo electrónico incorrecto?"}</p>
          </div>
          <p>Ingresa en este campo el código de confirmación que te hemos enviado {number ? "al número celular" : "por correo electrónico"}</p>

          <div className={`flex flex-col gap-2`}>
            <label className="font-bold">Código de confirmación</label>
            <input className="h-10 w-full bg-white rounded-md px-4 text-gray-800 border border-gray-200 shadow-sm lg:w-96" maxLength="4" onChange={(e) => setCode(e.target.value)} value={code} type="text" />
            {codeErr ? <span className="text-red">Código inválido</span> : null}
            {notChanged ? <span className="text-red">Ocurrió un problema con el servidor, intenta nuevamente</span> : null}
          </div>
          <Button className={`lg:w-96 text-center`} onClick={validateCode}>
            {loading ? <BallClipRotate color={"#A8CC46"} loading /> : "CONFIRMAR"}
          </Button>
          <div onClick={() => setSection("home")} className="underline text-green-dark cursor-pointer">
            <p>Cancelar</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckCode;
