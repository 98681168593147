import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { BallBeat } from "react-pure-loaders";
import { getToken, changePassword } from "../../services/api";
import { Link } from "react-router-dom";
import Button from "../common/Button";
import { useLocation } from "../../hooks/location";
import svgHidden from "../../assets/svg/hidden.svg";
import svgView from "../../assets/svg/view.svg";

const idProduct = 4;

const PasswordSet = (props) => {
  const [eMail, setEMail] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const { location, ip } = useLocation();

  const sendNewPassword = async () => {
    if (!newPassword) return setPassError(true);
    if (newPassword.length < 6) return setPassError(true);
    if (newPassword.length > 30) return setPassError(true);
    if (newPassword.search(/\d/) === -1) return setPassError(true);
    if (newPassword.search(/[a-zA-Z]/) === -1) return setPassError(true);
    setPassError(false);
    setLoading(true);
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;
    const data = {
      idProduct,
      eMail,
      userName: eMail,
      coordinates: location,
      newPassword,
      ipAddress: ip,
      userAgent: window.navigator.userAgent,
      password: newPassword,
    };
    changePassword(data, validToken)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setSuccess(true);
          return setTimeout(() => props.history.push("/login"), 3000);
        }
        setServerError(true);
        setLoading(false);
      })
      .catch((err) => {
        setServerError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    let validCode = sessionStorage.getItem("code-valid");
    if (!validCode) return props.history.push("/recupera");

    let recoveryMail = sessionStorage.getItem("recoveryEmail");
    if (!recoveryMail) return props.history.push("/recupera");
    setEMail(sessionStorage.getItem("recoveryEmail"));
  }, []);

  return (
    <div className="bg-white relative w-full flex justify-center items-start flex-1">
      <div className="container flex flex-col gap-4 p-4 ">
        <div className="flex flex-col gap-4 py-4 max-w-4xl">
          <div className="w-full flex flex-col gap-2">
            <h1 className="text-3xl font-extra-bold text-black-primary"> Ingresa tu nueva contraseña</h1>
          </div>

          <div className="w-full flex flex-col gap-4 rounded-lg bg-[#f0f0f0] p-4">
            <div className={`flex flex-col gap-2`}>
              <label className="font-bold">Nueva contraseña</label>
              <div className="relative w-full flex items-center lg:w-3/5">
                <input className={`w-full ${serverError ? "border-red" : ""}`} type={showPassword ? "text" : "password"} placeholder="Nueva contraseña" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                <img className="absolute right-4 cursor-pointer my-0 mx-auto" src={showPassword ? svgHidden : svgView} width="24" onClick={() => setShowPassword(!showPassword)} alt="eye" />
              </div>
              {serverError ? <span className="text-red">Error en el servidor</span> : null}
              <small>La contraseña debe ser de al menos 6 caracteres y debe incluir al menos 1 número</small>
            </div>
            <Button className="self-start" onClick={sendNewPassword}>
              {loading ? <BallBeat color={"#fff"} loading /> : "CAMBIAR CONTRASEÑA"}
            </Button>
            {success ? <span className="text-green-primary">Contraseña actualizada correctamente, redirigiendo a inicio de sesión...</span> : null}
            <Link to="/recupera-opcion" className="text-blue-primary hover:text-blue-light active:text-blue-dark  font-bold transition-all">
              Cambia opción de recuperación
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordSet;
