import React from "react";
import "../account/newStyles.scss";

const DemoButton = ({ callback }) => (
  <>
    {process.env.REACT_APP_ENV === "dev" && (
      <div onClick={callback} className="absolute top-4 right-4 bg-green-primary font-bold p-4 rounded-lg text-white">
        DEMO
      </div>
    )}
  </>
);

export default DemoButton;
