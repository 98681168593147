import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getFile, getToken, uploadFile } from "../../services/api";
import Compressor from "compressorjs";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import Button from "./Button";
import svgAnverso from "../../assets/svg/anverso.svg";
import svgReverso from "../../assets/svg/reverso.svg";
import svgSelfie from "../../assets/svg/selfie.svg";
import svgDocument from "../../assets/svg/anverso.svg";
import svgPlus from "../../assets/svg/plus.svg";

const idProduct = 4;

const DocumentItem = ({ document: d, validate = () => null }) => {
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState(false);
  const [completed, setCompleted] = useState(false);

  //Errors
  const [fileSizeError, setFileSizeError] = useState(false);
  const [serverError, setServerError] = useState(false);

  const { trackPageView } = useMatomo();

  const handleFile = async (e) => {
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;

    let user = JSON.parse(sessionStorage.getItem("loggedUser"));

    setLoading(true);
    let file = e.target.files[0];
    if (file.size > 10000000) return setFileSizeError(true);
    if (!isDocument(d.idDocument)) {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 1024,
        success(result) {
          // result.name.substring(result.name.lastIndexOf('.') + 1);
          let extension = result.type.replace("image/", "");
          let reader = new FileReader();
          reader.onprogress = (e) => {
            setLoading(true);
          };
          reader.onload = (e) => {
            setDoc(e.target.result);
            const finalString = e.target.result.replace(`data:image/${extension};base64,`, "");
            const data = {
              idProduct,
              idCustomer: user.customerId,
              idDocument: d.idDocument,
              fileName: result.name,
              fileExtension: extension,
              fileContent: finalString,
            };
            uploadFile(data, validToken)
              .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                  trackPageView({
                    documentTitle: "Pictures Loading",
                    href: "https://dfi.world",
                  });
                  validate(d.idDocument);
                  return setCompleted(true);
                }
                return setServerError(true);
              })
              .catch((err) => setLoading(false));
          };
          reader.readAsDataURL(result);
        },
        error(err) {
          console.log(err.message);
        },
      });
    } else {
      let extension = file.type.replace("application/", "");
      let reader = new FileReader();
      reader.onprogress = (e) => {
        setLoading(true);
      };
      reader.onload = (e) => {
        console.log(e);
        setDoc(e.target.result);
        const finalString = e.target.result.replace(`data:application/${extension};base64,`, "");
        const data = {
          idProduct,
          idCustomer: user.customerId,
          idDocument: d.idDocument,
          fileName: file.name,
          fileExtension: extension,
          fileContent: finalString,
        };
        uploadFile(data, validToken)
          .then((res) => {
            setLoading(false);
            if (res.status === 200) {
              trackPageView({
                documentTitle: "Pictures Loading",
                href: "https://dfi.world",
              });
              validate(d.idDocument);
              return setCompleted(true);
            }
            return setServerError(true);
          })
          .catch((err) => setLoading(false));
      };
      reader.readAsDataURL(file);
    }
  };

  const showDefaultImage = (idDocument) => {
    switch (idDocument) {
      case 27:
        return svgAnverso;
      case 30:
        return svgReverso;
      case 39:
        return svgSelfie;
      default:
        return svgDocument;
    }
  };

  const isDocument = (idDocument) => idDocument !== 27 && idDocument !== 30 && idDocument !== 39;

  useEffect(() => {
    const a = async () => {
      let response = await getToken();
      if (!response) return;
      let validToken = response.data.token;

      let { customerId } = JSON.parse(sessionStorage.getItem("loggedUser"));

      const data = {
        idProduct,
        idCustomer: customerId,
        idDocument: d.idDocument,
      };

      getFile(data, validToken)
        .then((res) => {
          if (res.status === 200) {
            setCompleted(true);
            validate(d.idDocument);
            setDoc(`data:image/${res.data.fileExtension.split(".")[1]};base64,${res.data.fileContent}`);
          }
        })
        .catch((err) => console.log(err));
    };
    a();
  }, []);

  return (
    <div className="flex flex-col gap-4 max-w-lg p-4 w-full">
      <div className="flex flex-col gap-4 items-center">
        <p className="font-bold text-black-primary w-full text-center">{d.documentDescription}</p>
        <div className={`flex flex-col justify-between items-center gap-4 w-full ${!doc ? "bg-[#e0e0e0]" : "bg-blue-lighter"} py-12 px-4 rounded-lg flex-1`}>
          <input onChange={handleFile} className="hidden" type="file" accept={isDocument(d.idDocument) ? "application/pdf" : "image/*"} name={`document-${d.idDocument}`} id={`document-${d.idDocument}`} capture={d.idDocument === 39 ? "user" : "environment"} />
          <img className="object-contain w-auto h-32" src={isDocument(d.idDocument) ? showDefaultImage(d.idDocument) : doc || showDefaultImage(d.idDocument)} alt={d.documentDescription} />
          {!doc && !isDocument(d.idDocument) && <span className="text-center w-full">Toma una foto desde tu celular o selecciónala desde tu computadora.</span>}
          {!doc && isDocument(d.idDocument) && <span className="w-full text-center">Sube el documento en formato PDF.</span>}
          <div className="flex gap-4 text-blue-primary hover:text-blue-light active:text-blue-dark cursor-pointer transition-all items-center" onClick={() => document.getElementById("document-" + d.idDocument).click()}>
            {!doc && <img src={svgPlus} alt="plus" width={32} />}
            <span className="font-bold">{loading ? "Cargando..." : completed ? (isDocument(d.idDocument) ? "Cambiar archivo" : "Cambiar imagen") : "Cargar " + d.documentDescription}</span>
          </div>
          {fileSizeError ? <small className="text-red">El archivo no debe pesar más de 10MB</small> : null}
          {serverError ? <small className="text-red">Error en el servidor, intenta nuevamente</small> : null}
        </div>
      </div>
    </div>
  );
};

DocumentItem.propTypes = {};
DocumentItem.defaultValues = {};

export default DocumentItem;
