import React, { useEffect, useState } from "react";
import { getContract, getToken } from "../../services/api";
import cookie from "react-cookies";

const idProduct = 4;

const GeneralContract = () => {
  const [contract, setContract] = useState();

  useEffect(() => {
    const gC = async () => {
      let validToken = cookie.load("token");
      if (!validToken) {
        let response = await getToken();
        if (!response.data) return;
        if (response.data) validToken = response.data.token;
      }

      let contRes = await getContract(idProduct, 0, validToken);
      setContract({ __html: contRes.data.htmlText });
    };
    gC();
  }, []);
  return (
    <div
      className="bg-white h-full w-full overflow-auto flex flex-col"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="overflow-auto flex-1 p-4 px-12" dangerouslySetInnerHTML={contract} />
    </div>
  );
};

export default GeneralContract;
