import React from "react";
import { Link } from "react-router-dom";
import Calculator from "../../calculator/Calculator";
import "../../../index.css";
import "../../../App.css";

const Cover = () => (
  <section className="w-full flex flex-col items-center lg:flex-row lg:items-start justify-center bg-blend-overlay lg:bg-blend-lighten lg:bg-cover lg:bg-top bg-repeat xl:pb-12 relative lg:min-h-lgcover xl:min-h-cover pt-24" style={{ backgroundImage: "url('/img/landing/pattern.svg')" }}>
    <div className="absolute w-full pointer-events-none h-full hidden lg:block z-10 " />

    <div className="container self-start flex flex-col lg:flex-row py-4 gap-8 justify-center lg:justify-between lg:items-start px-4 -pt-4 z-10 lg:py-4 h-full">
      {/* <div className="flex flex-col w-full lg:w-1/2 gap-4">
        <h1 className="text-3xl lg:text-5xl font-bold text-green-primary lg:mt-8">
          ¡<span className="lg:text-[4rem]">0%</span> de interés en tu primer préstamo!
        </h1>
        <h2 className="text-2xl text-black-primary lg:text-green-primary lg:hidden">Obtén hasta $2,000 pesos en tu primer crédito.</h2>
      </div> */}
      <div className="lg:flex flex-col w-full lg:w-2/3 gap-4 h-full lg:py-12 hidden">
        {/* <div className="flex flex-col gap-2 w-full items-end">
          <div className="flex flex-col">
            <img src={"/img/landing/cero.png"} alt="Cero %" className="w-60" />
            <h1 className="text-3xl lg:text-4xl font-bold text-green-primary lg:mt-8  h-full flex items-center max-w-md">de interés en tu primer préstamo.</h1>
          </div>
        </div> */}
        <img src={"/img/landing/person.png"} alt="Hombre con confianza" className="lg:w-1/2 h-[780px] object-cover object-right hidden lg:block absolute bottom-0 left-0" />
      </div>
      <div className="flex flex-col gap-4 justify-center h-full min-h-[780px]">
        <h1 className="text-2xl font-bold text-black text-left ">Solicita tu préstamo ahora</h1>
        <Calculator home={true} />
        <div className="p-4 bg-green-light flex items-center gap-4 w-full max-w-lg justify-between">
          <span className="w-3/4">Los servicios 4FINANCE, S.A. DE C.V., SOFOM, E.N.R. (“4FINANCE”) son debidamente regulados por CONDUSEF</span>
          <Link to="/informacion-regulatoria" className="cursor-pointer text-green-dark hover:text-green-primary transition-all font-bold jufisty-end flex">
            Ver más
          </Link>
        </div>
      </div>
    </div>
  </section>
);

export default Cover;
