import React, { useEffect } from "react";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  dataLayer: {
    event: "pageChange",
    page: {
      url: "/informacion-regulatoria",
      referrer: sessionStorage.getItem("utm") || "/",
    },
  },
  dataLayerName: "dataLayer",
};

export const RegulatoryInfo = () => {
  TagManager.dataLayer(tagManagerArgs);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-white relative w-full flex justify-center flex-1">
      <div className="container flex flex-col gap-4 p-4 max-w-4xl">
        <div className="flex flex-col gap-4 py-4">
          {/* <DemoButton callback={fillDemo} /> */}
          <div className="w-full flex flex-col gap-2">
            <div className="flex w-full justify-between">
              <img className="w-48 pb-8" src={"/img/navbar/logo-doctor-peso.svg"} alt="doctor peso logo" />
              <img className="w-60 pb-8" src={"/img/amfe.png"} alt="AMFE Logo" />
            </div>
            <p className="pb-4">
              <strong>4FINANCE, S.A. DE C.V., SOFOM, E.N.R. (“4FINANCE”)</strong> es una entidad perteneciente al sistema financiero mexicano. Se encuentra debidamente regulada por la{" "}
              <strong>Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (“CONDUSEF”)</strong> y por la <strong>Comisión Nacional Bancaria y de Valores (“CNBV”)</strong>, por lo que hace a cuestiones relacionadas con la prevención de lavado de dinero y combate al
              financiamiento al terrorismo. Asimismo, <strong>4FINANCE</strong> es miembro de la <strong>Asociación Mexicana de Entidades Financieras Especializadas (“AMFE”)</strong>, cuya misión es la de favorecer entre las asociadas y autoridades un entorno de dialogo, comunicación y divulgación
              de las mejores prácticas en materia de crédito especializado.
            </p>
            <p className="pb-4">
              Puedes verificar que nuestro registro ante CONDUSEF se encuentra vigente en la siguiente página de internet:{" "}
              <a href="https://www.buro.gob.mx" target="_blank" className="font-bold text-blue-primary hover:text-blue-light active:text-blue-dark transition-all cursor-pointer">
                https://www.buro.gob.mx
              </a>
            </p>
            <p className="pb-4">Los servicios financieros que brindamos se encuentran debidamente registrados y regulados por la CONDUSEF. Son transparentes para todos nuestros clientes y seguros. </p>
            <p className="pb-4">
              <strong>4FINANCE</strong> es titular de la marca “DOCTOR PESO” y nuestra única página oficial es:{" "}
              <a href="https://doctorpeso.com.mx/" target="_blank" className="font-bold text-blue-primary hover:text-blue-light active:text-blue-dark transition-all cursor-pointer">
                https://doctorpeso.com.mx/
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
