import React from "react";
import "./common.scss";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Social = () => {
  return (
    <>
      {/* <a
        className="group fixed shadow-md bottom-4 right-4 z-50 rounded-full h-16 w-16 bg-green-dark hover:bg-green-light transition-all flex items-center justify-center p-4"
        rel="noopener noreferrer"
        target="_blank"
        href="https://app-digital.mitrol.cloud/vivuswebchat/index.html?serviceRoute=ChatVivu1"
      >
        <FontAwesomeIcon className="text-white group-hover:text-green-dark text-2xl" icon={faComment} />
      </a> */}
    </>
  );
};
