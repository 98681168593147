import React, { useState, useEffect } from "react";
import "./newStyles.scss";
import { withRouter } from "react-router-dom";
import { getToken, getStatus, getAnalytics, setDirectDebitAuthorization, setIdValidation, getDocumentList } from "../../services/api";
import TagManager from "react-gtm-module";
import publicIp from "public-ip";
import Button from "../common/Button";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import DocumentItem from "../common/DocumentItem";
import MainButton from "../common/MainButton";
import { BallBeat } from "react-pure-loaders";
import { useAnalytics } from "../../hooks/use-analytics";
import svgInfoBlue from "../../assets/svg/info-blue.svg";

const idProduct = 4;

const Identificacion = (props) => {
  const [cargadoAnverso, setCargadoAnverso] = useState(false);
  const [cargadoReverso, setCargadoReverso] = useState(false);
  const [user, setUser] = useState(null);
  const [, setToken] = useState(null);
  const [, setMobile] = useState(false);
  // variables validación ID
  const [, setIdValid] = useState(false);
  const [idLoading, setIdLoading] = useState(false);
  const [idError, setIdError] = useState(false);
  const [opportunities, setOpportunities] = useState(0);
  const [continueDisabled, setContinueDisabled] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [ready, setReady] = useState(false);

  const { trackPageView } = useMatomo();
  const { dataLayerPushEvent } = useAnalytics();

  /**
   * Get token to request.
   * @returns
   */
  const askToken = async () => {
    let response = await getToken();
    if (!response) return;
    setToken(response.data.token);
  };

  /**
   * Pass a value to determine if the specified document has been loaded.
   * @param {number} idDocument
   */
  const validateDocument = (idDocument) => {
    setDocumentList(
      documentList.map((doc, key) => {
        if (doc.idDocument === idDocument) {
          doc.uploaded = true;
        }
        return doc;
      })
    );
  };

  /**
   * It is called once the form is completed.
   */
  const manageValidarId = async () => {
    const myIp = await publicIp.v4();
    let token = await getToken();
    token = token.data.token;
    let setIdData = {
      message: "",
      identityValidated: false,
      userAgent: navigator.userAgent,
      ip: myIp,
      idCustomer: user.customerId,
      idProduct: idProduct,
      token,
    };
    setIdLoading(false);
    setIdValid(true);
    setIdError(false);
    setIdData.message = "OK: ID y selfie válidos.";
    setIdData.identityValidated = true;

    //TODO: Revisar situación de "Débito autorizado"

    setIdValidation(setIdData)
      .then(setDebitAuth)
      .then(() => {
        getStatus(idProduct, user.customerId, false, token).then((res) => {
          if (res.status && res.data.idStatus === 4) {
            trackPageView({
              documentTitle: "Form Rejected",
              href: "https://dfi.world",
            });
            localStorage.id_opportunities = 0;
            props.history.push("/rejected");
          } else {
            trackPageView({
              documentTitle: "Final Approval",
              href: "https://dfi.world",
            });

            trackPageView({
              documentTitle: "Successful loan signing",
              href: "https://dfi.world",
            });

            dataLayerPushEvent("Credit_approved", user.customerId);
            localStorage.id_opportunities = 0;
            props.history.push("/identity-validated");
          }
        });
      })
      .catch(() => {
        trackPageView({
          documentTitle: "Form Rejected",
          href: "https://dfi.world",
        });
        props.history.push("/denied");
      });
  };

  /**
   * Handle button continue.
   */
  const handleContinueClick = async () => {
    setContinueDisabled(true);
    const myIp = await publicIp.v4();
    let token = await getToken();
    token = token.data.token;
    let setIdData = {
      message: "",
      identityValidated: false,
      userAgent: navigator.userAgent,
      ip: myIp,
      idCustomer: user.customerId,
      idProduct: idProduct,
      token: token,
    };

    await manageValidarId(setIdData);
  };

  useEffect(() => {
    const checkUser = async (user) => {
      let response = await getToken();
      if (!response) return;
      let validToken = response.data.token;
      getStatus(idProduct, user.customerId, false, validToken).then((res) => {
        if (res.status && res.data.idStatus === 1) {
          if (res.data.idSubStatus === 180) return props.history.push("/registration/personal-details");
          if (res.data.idSubStatus === 181) return props.history.push("/registration/employment-details");
          if (res.data.idSubStatus === 182) return props.history.push("/registration/nip-bureau");
          if (res.data.idSubStatus === 183) return props.history.push("/registration/nip-bureau");
          if (res.data.idSubStatus === 184) return props.history.push("/registration/nip-bureau");
          if (res.data.idSubStatus === 185) return props.history.push("/registration/nip-bureau");
          if (res.data.idSubStatus === 195) return props.history.push("/registration-complete");
          if (res.data.idSubStatus === 196) return props.history.push("/pre-approved");
          if (res.data.idSubStatus === 203) return props.history.push("/pre-approved");
          if (res.data.idSubStatus === 206) return props.history.push("/application-review");
          if (res.data.idSubStatus === 217) return props.history.push("/dashboard/id");
          if (res.data.idSubStatus === 254) return props.history.push("/denied");
          if (res.data.idSubStatus === 257) return props.history.push("/identity-validated");
          if (res.data.idSubStatus === 247) return props.history.push("/dashboard/id");

          if (res.data.idSubStatus === 270) return props.history.push("/loan-review");
          if (res.data.idSubStatus === 271) return props.history.push("/loan-review");
          if (res.data.idSubStatus === 273) return props.history.push("/loan-review");

          if (res.data.idSubStatus === 218) {
            setCargadoAnverso(true);
            return setCargadoReverso(true);
          }
          if (res.data.idSubStatus === 219) {
            setCargadoAnverso(true);
            return setCargadoReverso(true);
          }
          if (res.data.idSubStatus === 248) return props.history.push("/application-complete");
        }
        if (res.status && res.data.idStatus === 4) {
          if (res.idSubStatus === 204) return props.history.push("/rejected");
          return props.history.push("/denied");
        }
        if (res.status && res.data.idStatus === 6) {
          return props.history.push("/application-complete");
        }
        if (res.status && res.data.idStatus === 7) {
          return props.history.push("/dashboard/general");
        }
      });

      //TAG MANAGER
      getAnalytics({ idCustomer: user.customerId, idProduct }, validToken).then((analytics) => {
        if (analytics.data) {
          const tagManagerArgs = {
            dataLayer: {
              event: "pageChange",
              page: {
                url: "/dashboard/id",
                referrer: sessionStorage.getItem("utm") || "/",
              },
              client: {
                hFN: analytics.data.hFN,
                hLN: analytics.data.hLN,
                hTN: analytics.data.hTN,
                hMA: analytics.data.hMA,
                dateOfBirth: analytics.data.dateOfBirth,
                returningClient: analytics.data.returningClient,
                identifiedBy: analytics.data.identifiedBy,
                registeredBy: analytics.data.registeredBy,
              },
              loans: {
                loansCount: analytics.data.loansCount,
              },
              lastest_loan: {
                status: analytics.data.status,
                id: analytics.data.id,
                repaymentDate: analytics.data.repaymentDate,
              },
              application: {
                id: analytics.data.application.id,
              },
            },
            dataLayerName: "dataLayer",
          };
          TagManager.dataLayer(tagManagerArgs);
        }
      });

      // const {
      //   data: { documentList },
      // } = await getDocumentList(
      //   {
      //     idProduct,
      //     idCustomer: user.customerId,
      //     idAction: 1,
      //   },
      //   validToken
      // );

      const documentList = [
        { idDocument: 27, documentDescription: "Identificacion Oficial" },
        { idDocument: 30, documentDescription: "Identificación Oficial Reverso" },
        { idDocument: 39, documentDescription: "Selfie" },
      ];

      setDocumentList(documentList);

      //TAG MANAGER
    };
    let approved = sessionStorage.getItem("APP");
    if (approved === "no") return props.history.push("/denied");
    let getUser = JSON.parse(sessionStorage.getItem("loggedUser"));

    if (!getUser) {
      let emptyCustomer = JSON.parse(sessionStorage.getItem("empty-customer"));
      setUser(emptyCustomer);
      checkUser(emptyCustomer);
      return;
    }
    setUser(getUser);
    checkUser(getUser);

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    if (demoUser) return setUser(demoUser);
    askToken();
    return;
    // let getToken = cookie.load('token')
    // if(!getToken) return props.history.push('/login')
    // setToken(getToken)
  }, []);

  useEffect(() => {
    setMobile(window.innerWidth < 900);
  });

  useEffect(() => {
    setReady(documentList.reduce((prev, doc) => (prev ? doc.uploaded : false), true));
  }, [documentList]);

  useEffect(() => {
    //Número de oportunidades utilizadas para validar la identificación
    setOpportunities(parseInt(localStorage.id_opportunities) || 0);
  }, []);

  const setDebitAuth = async () => {
    let response = await getToken();
    const validToken = response.data.token;
    const myIp = await publicIp.v4();
    let coords = sessionStorage.getItem("coords");
    if (!coords) coords = "Location blocked";
    const debitData = {
      userAgent: navigator.userAgent,
      ip: myIp,
      authorized: true,
      coordinates: coords,
      idProduct,
      idCustomer: user.customerId,
    };
    await setDirectDebitAuthorization(debitData, validToken);
  };

  return (
    <>
      <div className="bg-white relative w-full flex justify-center items-start flex-1">
        <div className="container flex flex-col gap-4 p-4 ">
          <div className="flex flex-col gap-4 py-4">
            {/* <DemoButton callback={fillDemo} /> */}
            <div className="w-full flex flex-col gap-2">
              <h1 className="text-3xl font-extra-bold text-green-primary">¡Ya casi tienes tu préstamo! </h1>
              <h2 className="text-xl font-bold text-black-primary">
                Para finalizar el proceso, solo necesitamos que nos compartas los siguientes <span className="font-bold">documentos</span> como son solicitados.
              </h2>
            </div>
            <div className="flex justify-between items-start gap-8 mb-8">
              <div className="w-full 2xl:w-2/3 flex flex-col gap-4 rounded-lg bg-[#f0f0f0] p-4">
                <div className="flex flex-col gap-4">
                  <p>
                    <span className={"font-bold"}>IMPORTANTE:</span> Para una correcta validación te pedimos los siguientes requisitos:
                  </p>
                  <ul className="list-disc pl-4">
                    <li className="font-bold">Que las fotografías de tu identificación sean a color </li>
                    <li className="font-bold">Totalmente legibles, es decir, que se puedan leer los datos de tu credencial </li>
                    <li className="font-bold">Sin flash </li>
                    <li className="font-bold">Que sean visibles los bordes de tu credencial </li>
                  </ul>
                  <div className="flex gap-2 items-start p-4 bg-blue-lighter w-full lg:w-2/3 rounded-lg">
                    <img src={svgInfoBlue} alt="obtain" width={24} height={24} className="self-start" />
                    <span className="text-blue-primary font-bold">Preferentemente saca las fotos desde tu celular (no escáner, no impresiones blanco y negro, no fotos guardadas en biblioteca)</span>
                  </div>
                </div>
                <div className="flex flex-col lg:grid lg:grid-cols-3 gap-4 flex-wrap justify-between">
                  {documentList.map((document) => (
                    <DocumentItem document={document} key={`document-${document.idDocument}`} validate={validateDocument} />
                  ))}
                </div>
                <div>
                  {!ready && (
                    <div className="flex flex-col py-8 px-4 rounded-lg text-center justify-center items-center flex-1 w-full bg-orange-lighter">
                      <h3 className="text-xl font-bold text-orange-primary ">Carga tus documentos para continuar</h3>
                      <p className="text-orange-primary">Verifica que los documentos que subas sean los solicitados, ya que no podremos procesar tu solicitud sin ellos.</p>
                    </div>
                  )}
                  {ready && !idLoading && documentList.length > 0 && (
                    <>
                      <div className="flex flex-col py-8 px-4  gap-4 rounded-lg bg-green-light text-center justify-center items-center flex-1 w-full">
                        {!idError && (
                          <>
                            <p className="text-xl font-bold text-green-primary">Tus documentos han sido cargados exitosamente</p>{" "}
                          </>
                        )}
                        <p className="text-green-primary">Da clic en el botón continuar para verificar tu identidad.</p>
                      </div>
                      <div className="flex flex-col lg:flex-row justify-between gap-4 my-4 items-center">
                        <Button className={`self-start w-full lg:w-auto text-center ${!continueDisabled ? "" : "bg-[#e0e0e0] cursor-default pointer-events-none"} `} onClick={handleContinueClick}>
                          Continuar
                        </Button>
                        <div className="flex flex-col">
                          <p className="text-xl text-black-primary font-bold">¿Necesitas ayuda?</p>
                          <p className="text-xl text-green-primary font-bol">Mándanos un WhatsApp</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="w-full hidden 2xl:flex 2xl:w-1/3 align-start rounded-lg bg-blue-lighter justify-start p-4 gap-4 h-auto self-start">
                <img src={svgInfoBlue} alt="obtain" width={24} height={24} className="self-start" />
                <div className="text-left font-light flex-1 text-blue-primary">Para finalizar el proceso, solo necesitamos que nos envíes tu identificación oficial (INE) y una selfie</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Identificacion);
