import React from "react";
import LinkButton from "../../common/LinkButton";
import svgEdit from "../../../assets/svg/edit.svg";

const Options = ({ user, setSection, maskedBank }) => {
  return (
    <div className="flex flex-col w-full max-w-2xl gap-4">
      <h3 className="text-xl text-black-primary font-bold">Revisión de la cuenta</h3>
      <div className="flex items-center justify-between bg-[#f0f0f0] p-4 rounded-lg">
        <div className="flex flex-col">
          <p className="text-gray-light">Tu nombre</p>
          <p className="font-bold">{user ? user.fullName : "Cargando..."}</p>
        </div>
      </div>

      <div className="flex items-center justify-between bg-[#f0f0f0] p-4 rounded-lg">
        <div className="flex flex-col">
          <p className="text-gray-light">Correo Electrónico</p>
          <p className="font-bold">{user ? user.eMail : "Cargando..."}</p>
        </div>
        {/* <LinkButton onClick={() => setSection("email")}>EDITAR</LinkButton> */}
      </div>

      <div className="flex items-center justify-between bg-[#f0f0f0] p-4 rounded-lg">
        <div className="flex flex-col">
          <p className="text-gray-light">Número de teléfono</p>
          <p className="font-bold">{user ? user.mobile : "Cargando..."}</p>
        </div>
        <div className="flex gap-2 cursor-pointer text-blue-primary" onClick={() => setSection("phone")}>
          <img src={svgEdit} alt="Editar" width={20} />
          <span className="font-bold">Editar</span>
        </div>
      </div>

      <div className="flex items-center justify-between bg-[#f0f0f0] p-4 rounded-lg">
        <div className="flex flex-col">
          <p className="text-gray-light">Contraseña</p>
          <p className="font-bold">******</p>
        </div>
        <div className="flex gap-2 cursor-pointer text-blue-primary" onClick={() => setSection("password")}>
          <img src={svgEdit} alt="Editar" width={20} />
          <span className="font-bold">Editar</span>
        </div>
      </div>

      <div className="flex items-center justify-between bg-[#f0f0f0] p-4 rounded-lg">
        <div className="flex flex-col">
          <p className="text-gray-light">
            Mis cuentas <br />
            (aquí depositamos tu préstamo)
          </p>
          <p className="font-bold">{maskedBank}</p>
        </div>
        <div className="flex gap-2 cursor-pointer text-blue-primary" onClick={() => setSection("payment")}>
          <img src={svgEdit} alt="Editar" width={20} />
          <span className="font-bold">Editar</span>
        </div>
      </div>
    </div>
  );
};

export default Options;
