import React, { useState } from "react";
import CheckCode from "./email/CheckCode";
import { sendCodeByNewEMail, getToken, getCustomerByMail } from "../../../services/api";
import { BallClipRotate } from "react-pure-loaders";
import Button from "../../common/Button";

const ChangeEmail = ({ history, setSection }) => {
  const [email, setEmail] = useState(null);
  const [emailErr, setEmailErr] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const idProduct = 4;

  const checkEmail = async () => {
    if (!email) return setEmailErr(true);
    let regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regEx.test(email.toLowerCase())) {
      setEmailErr(false);
      setEmailError(false);
      setLoading(true);
      let response = await getToken();
      let validToken = response.data.token;
      let coords = sessionStorage.getItem("coords");
      if (!coords) coords = "Location blocked";
      let myIp = sessionStorage.getItem("ip");
      if (!myIp) myIp = "192.168.1.254";
      let user = JSON.parse(sessionStorage.getItem("loggedUser"));
      const data = {
        newEMail: email,
        newMobile: "",
        userAgent: navigator.userAgent,
        remoteIp: myIp,
        coordinates: coords,
        isNIP: false,
        idCustomer: user.customerId,
        idProduct,
      };
      sendCodeByNewEMail(data, validToken).then((res) => {
        if (res.status === 200) {
          setValidEmail(true);
          setLoading(false);
        }
        setEmailError(true);
        setLoading(false);
      });
    } else {
      setEmailErr(true);
      setLoading(false);
    }
  };

  return (
    <div>
      {validEmail ? (
        <CheckCode history={history} email={email} setValidEmail={setValidEmail} setSection={setSection} />
      ) : (
        <div className="flex flex-col gap-4">
          <h3 className="text-2xl">Cambiar correo electrónico</h3>

          <div className={`flex flex-col gap-2`}>
            <label className="font-bold">Nueva dirección de correo electrónico</label>
            <input className="h-10 w-full bg-white rounded-md px-4 text-gray-800 border border-gray-200 shadow-sm lg:w-96" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="ejemplo@email.com" type="email" />
            {emailErr ? <span className="text-red">Ingresa un correo válido</span> : null}
            {emailError ? <span className="text-red">Esta cuenta de correo ya está en uso o hubo un error interno</span> : null}
          </div>
          <Button className={`lg:w-96 text-center`} onClick={checkEmail}>
            {loading ? <BallClipRotate color={"#A8CC46"} loading /> : "CAMBIAR DIRECCIÓN DE CORREO ELECTRÓNICO"}
          </Button>

          <div onClick={() => setSection("home")} className="underline text-green-dark cursor-pointer">
            <p>Cancelar</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeEmail;
