import React, { useState } from "react";
import { getToken, login, changePassword } from "../../../services/api";
import { BallClipRotate } from "react-pure-loaders";
import Button from "../../common/Button";
import svgCancel from "../../../assets/svg/cancel.svg";
import svgHidden from "../../../assets/svg/hidden.svg";
import svgView from "../../../assets/svg/view.svg";

const idProduct = 4;

const ChangePassword = ({ setSection }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPasswordErr, setOldPasswordErr] = useState(false);
  const [newPasswordErr, setNewPasswordErr] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkPassword = async () => {
    if (newPassword.length < 6) return setNewPasswordErr(true);
    if (newPassword.length > 30) return setNewPasswordErr(true);
    if (newPassword.search(/\d/) === -1) return setNewPasswordErr(true);
    if (newPassword.search(/[a-zA-Z]/) === -1) return setNewPasswordErr(true);
    setNewPasswordErr(false);
    setLoading(true);
    let response = await getToken();
    let validToken = response.data.token;
    let coords = sessionStorage.getItem("coords");
    if (!coords) coords = "Location blocked";
    let myIp = sessionStorage.getItem("ip");
    if (!myIp) myIp = "192.168.1.254";
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    const data = {
      idProduct,
      userName: user.eMail,
      password: oldPassword,
      ipAddress: myIp,
      userAgent: navigator.userAgent,
      coordinates: coords,
    };
    login(data, validToken).then((res) => {
      if (res.status === 200) {
        const changeData = {
          idProduct,
          eMail: user.eMail,
          userName: user.eMail,
          newPassword,
          ipAddress: myIp,
          userAgent: navigator.userAgent,
          coordinates: coords,
          password: newPassword,
        };
        return changePassword(changeData, validToken).then((changed) => {
          if (changed.status === 200) {
            setSuccess(true);
            setLoading(false);
          }
          setError(true);
          setLoading(false);
        });
      }
      setLoading(false);
      setOldPasswordErr(true);
    });
  };

  return (
    <div className="flex flex-col gap-4">
      {success ? (
        <div className="flex flex-col gap-4">
          <h4 className="text-xl font-bold text-black-primary">Contraseña actualizada correctamente</h4>
          <div onClick={() => setSection("home")} className="underline text-green-dark cursor-pointer">
            <p>Regresar</p>
          </div>
        </div>
      ) : (
        <>
          <h3 className="text-xl font-bold text-black-primary">Cambiar contraseña</h3>
          <div className="w-full flex flex-col gap-4 rounded-lg bg-[#f0f0f0] p-4 lg:max-w-4xl">
            <div className={`flex flex-col gap-2`}>
              <label className="font-bold">Ingresar contraseña anterior</label>
              <div className="relative w-full flex items-center lg:w-96">
                <input className={`w-full ${oldPasswordErr ? "border-red" : ""}`} onChange={(e) => setOldPassword(e.target.value)} value={oldPassword} type={showOldPassword ? "text" : "password"} />
                <img className="absolute right-4 cursor-pointer my-0 mx-auto" src={showOldPassword ? svgHidden : svgView} width="24" onClick={() => setShowOldPassword(!showOldPassword)} alt="eye" />
              </div>
              {oldPasswordErr ? <span className="text-red">Contraseña incorrecta.</span> : null}
            </div>

            <div className={`flex flex-col gap-2`}>
              <label className="font-bold">Ingresar nueva contraseña</label>
              <div className="relative w-full flex items-center lg:w-96">
                <input className={`w-full ${newPasswordErr ? "border-red" : ""}`} onChange={(e) => setNewPassword(e.target.value)} value={newPassword} type={showNewPassword ? "text" : "password"} />
                <img className="absolute right-4 cursor-pointer my-0 mx-auto" src={showNewPassword ? svgHidden : svgView} width="24" onClick={() => setShowNewPassword(!showNewPassword)} alt="eye" />
              </div>

              {newPasswordErr ? <span className="text-red">Las contraseñas no coinciden.</span> : null}
              {error ? <span className="text-red">Ocurrió un error en el servidor al actualizar tu contraseña, intenta nuevamente.</span> : null}
            </div>

            <div className="flex flex-col lg:flex-row gap-4">
              <Button className={`self-start`} onClick={checkPassword}>
                {loading ? <BallClipRotate color={"#A8CC46"} loading /> : "Confirmar"}
              </Button>
              <div className="flex gap-2 cursor-pointer items-center" onClick={() => setSection("home")}>
                <img src={svgCancel} width={20} alt="cancel" />
                <span className="text-red font-bold transition-all" to="/login">
                  Cancelar
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChangePassword;
