import React from "react";
import "../../newStyles.scss";
import Button from "../../../common/Button";

const Debit = ({ balance, setCargos }) => {
  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      setCargos("resumen");
    }
  };

  return (
    <div className="flex flex-col gap-4 py-8 px-4">
      <h4 className="text-xl font-bold text-black-primary">Tarjeta de débito</h4>
      <div className="flex flex-col lg:flex-row justify-between gap-4 w-full">
        <div className="flex flex-col lg:w-1/2 w-full">
          <p>
            Por favor presiona el botón de abajo y serás redirigido a la plataforma de pago. Ahí deberás llenar toda la información requerida y podrás hacer tu pago usando tu número de préstamo: <b>{balance.reference}</b>
          </p>
          <p>La seguridad de tu información está garantizada</p>
        </div>
        <div className="flex gap-4 w-full lg:w-1/2" style={{ marginBottom: "1rem" }}>
          <img className="w-1/2" src="/img/mastercard.svg" alt="mastercard" />
          <img className="w-1/2" src="/img/visa.png" alt="visa" />
        </div>
      </div>
      <a href="https://4finance.mit.com.mx/vivWeb/" rel="noopener noreferrer" target="_blank" className="self-center">
        <Button className="text-center">Pagar</Button>
      </a>
    </div>
  );
};

export default Debit;
