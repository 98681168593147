import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer, faClock, faTimes } from "@fortawesome/free-solid-svg-icons";
import LinkButton from "./LinkButton";

export const ErrorTimeout = ({ location }) => {
  return (
    <div className="bg-white relative w-full flex justify-center flex-1">
      <div className="container flex flex-col gap-8 px-4 py-12 self-center">
        <div className="w-full flex flex-col gap-4 text-center">
          <div className="background-error-icon">
            <FontAwesomeIcon icon={faServer} />
            <span style={{ fontSize: "3rem", marginLeft: "1rem" }}>
              <FontAwesomeIcon icon={faClock} />
            </span>
          </div>
          <h1 className="text-3xl text-green-primary font-extra-bold"> Verificando información</h1>
          <h2 className="text-xl font-bold text-red">Este proceso está tomando más tiempo del previsto.</h2>

          <p>
            Espera unos segundos y presiona el botón de <b>Verificar estado</b> para continuar con tu aplicación.
          </p>
          <LinkButton to="/pre-approved" className="self-center">
            Verificar estado
          </LinkButton>
          <p>{location.state ? `${location.state.endpoint} code ${location.state.status}` : null}</p>
        </div>
      </div>
    </div>
  );
};
