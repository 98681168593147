import React, { useState } from "react";
import CheckCode from "./email/CheckCode";
import { getToken, sendCodeByNewSMS } from "../../../services/api";
import { BallClipRotate } from "react-pure-loaders";
import Button from "../../common/Button";
import svgCancel from "../../../assets/svg/cancel.svg";

const idProduct = 4;

const ChangePhone = ({ history, setSection }) => {
  const [number, setNumber] = useState(null);
  const [numberErr, setNumberErr] = useState("");
  const [validNumber, setValidNumber] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkNumber = async () => {
    if (number.length < 10) return setNumberErr(true);
    let reg = /[0-9]{10}$/;
    if (reg.test(number)) {
      setLoading(true);
      let response = await getToken();
      let validToken = response.data.token;
      let coords = sessionStorage.getItem("coords");
      if (!coords) coords = "Location blocked";
      let myIp = sessionStorage.getItem("ip");
      if (!myIp) myIp = "192.168.1.254";
      let user = JSON.parse(sessionStorage.getItem("loggedUser"));
      const data = {
        newEMail: "",
        newMobile: number,
        userAgent: navigator.userAgent,
        remoteIp: myIp,
        coordinates: coords,
        isNIP: false,
        idCustomer: user.customerId,
        idProduct,
      };

      sendCodeByNewSMS(data, validToken).then((res) => {
        if (res.status === 200) {
          setValidNumber(true);
          setLoading(false);
        }
        setNumberErr(true);
        setLoading(false);
      });
      setNumberErr(false);
      return setValidNumber(true);
    }
    setNumberErr(true);
  };

  return (
    <div>
      {validNumber ? (
        <CheckCode history={history} number={number} setValidNumber={setValidNumber} setSection={setSection} />
      ) : (
        <div className="flex flex-col gap-4">
          <h3 className="text-xl font-bold text-black-primary">Cambiar número celular</h3>
          <div className="w-full flex flex-col gap-4 rounded-lg bg-[#f0f0f0] p-4 lg:max-w-4xl">
            <div className={`flex flex-col gap-2 lg:w-1/2`}>
              <label className="font-bold">Ingresa tu nuevo número celular</label>
              <input className={`w-full ${numberErr ? "border-red" : ""}`} maxLength="10" onChange={(e) => setNumber(e.target.value)} value={number} placeholder="Ingresa tu número a 10 dígitos" type="text" />
              {numberErr ? <span className="text-red">Número incorrecto</span> : null}
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
              <Button className={`self-start`} onClick={checkNumber}>
                {loading ? <BallClipRotate color={"#A8CC46"} loading /> : "Cambiar número"}
              </Button>
              <div className="flex gap-2 cursor-pointer items-center" onClick={() => setSection("home")}>
                <img src={svgCancel} width={20} alt="cancel" />
                <span className="text-red font-bold transition-all" to="/login">
                  Cancelar
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePhone;
