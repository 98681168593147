import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const LinkButton = ({ children, onClick = () => null, to, className, icon, type = "primary" }) => {
  return (
    <Link to={to} onClick={onClick} className={`${type === "primary" ? "bg-green-primary hover:bg-green-clear active:bg-green-dark" : "bg-orange-primary hover:bg-orange-light active:bg-orange-dark"} transition-all font-extra-bold text-white py-2 px-12  rounded-full flex ${className}`}>
      {icon && <img src={icon} width={24} className="mr-2" />}
      {children}
    </Link>
  );
};

LinkButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

LinkButton.defaultValues = {
  onClick: () => null,
  className: "",
};

export default LinkButton;
