import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import { getContract, getToken } from "../../services/api";

const idProduct = 4;

const Contract = () => {
  const [contract, setContract] = useState("");

  useEffect(() => {
    const askToken = async () => {
      let validToken = cookie.load("token");
      if (!validToken) {
        let response = await getToken();
        if (!response.data) return;
        if (response.data) validToken = response.data.token;
      }

      let contRes = await getContract(idProduct, 0, validToken);

      setContract(contRes.data.htmlText);
      return;
    };
    askToken();
  }, []);
  return (
    <div className="bg-white relative w-full flex justify-center flex-1 pt-12 pb-32">
      <div className="container max-w-3xl flex flex-col px-4" dangerouslySetInnerHTML={{ __html: contract }}></div>
    </div>
  );
};

export default Contract;
