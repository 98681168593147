import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import TagManager from "react-gtm-module";
// import ReactPixel from 'react-facebook-pixel'
import Pixel from "./pixel";

export const PreApproved = () => {
  //TAG MANAGER
  const tagManagerArgs = {
    dataLayer: {
      event: "pageChange",
      page: {
        url: "/application-complete",
        referrer: sessionStorage.getItem("utm") || "/",
      },
    },
    dataLayerName: "dataLayer",
  };
  TagManager.dataLayer(tagManagerArgs);
  //TAG MANAGER
  // let options = {autoConfig: true, debug: true}
  // ReactPixel.init('285010758598337', options)
  // ReactPixel.pageView()
  return (
    <div className="bg-white relative w-full flex justify-center flex-1">
      <div className="container flex flex-col gap-8 px-4 py-12 mb-32">
        <div className="w-full flex flex-col gap-4">
          <h1 className="text-3xl font-extra-bold text-green-primary">¡Felicidades!, tu préstamo ha sido aprobado.</h1>
          <h2 className="text-xl font-bold">Tu dinero está en camino.</h2>

          <p> En cuanto depositemos el dinero a tu cuenta, podrás acceder al administrador de tu préstamo iniciando sesión en la plataforma</p>
          <Pixel name="PIXEL 1" />
        </div>
      </div>
    </div>
  );
};
