import React, { useState, useEffect } from "react";
import cookie from "react-cookies";
import Axios from "axios";
import { withRouter } from "react-router-dom";
import { useLocation } from "../../../../hooks/location";
import { getToken, changeDebit, getBanks } from "../../../../services/api";
import Button from "../../../common/Button";
import svgCancel from "../../../../assets/svg/cancel.svg";
import svgBack from "../../../../assets/svg/back.svg";

const idProduct = 4;

const PaymentDebit = ({ history, setSection }) => {
  const [token, setToken] = useState(null);
  const [card, setCard] = useState("");
  const [bankError, setBankError] = useState("");
  const [cardErr, setCardErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [currentChange, setCurrentChange] = useState(false);
  const { location, ip } = useLocation();

  const checkClabe = async () => {
    let response = await getToken();
    if (!response) return setCardErr(true);
    let validToken = response.data.token;
    if (!card) return setCardErr(true);
    let regEx = /^([0-9]{16})$/;
    const user = JSON.parse(sessionStorage.getItem("loggedUser"));
    const data = {
      debitCard: card,
      customerIP: ip,
      coordinates: location,
      userAgent: window.navigator.userAgent,
      idCustomer: user.customerId,
      idProduct: idProduct,
    };
    if (regEx.test(card)) {
      setCardErr(false);
      changeDebit(data, validToken)
        .then((res) => {
          setSuccess(true);
        })
        .catch((err) => {
          if (err.response.status === 403) return setCurrentChange(true);
          setCardErr(true);
        });
    } else {
      setCardErr(true);
    }
  };

  useEffect(() => {
    let getToken = cookie.load("token");
    if (!getToken) return history.push("/login");
    setToken(getToken);
  }, []);

  return (
    <div>
      {success ? (
        <div className="w-full flex flex-col gap-4 rounded-lg bg-[#f0f0f0] p-4 lg:max-w-4xl">
          <h3 className="text-2xl">Listo, el cambio de tu tarjeta de débito está en trámite</h3>
          <p className="text-gray-500">Realizaremos una validación y en breve te informaremos el resultado, espéralo en tu correo electrónico en un plazo de 24 horas hábiles</p>
          <p className="text-red">
            Al continuar, acepto que este nuevo número es válido para actualizar mi formato de autorización de cargo automático por el monto de las parcialidades que en su caso tenga pendientes. Tarjeta de débito número: <br />
            {card}
          </p>
          <div className="flex gap-2 cursor-pointer items-center" onClick={() => setSection("home")}>
            <img src={svgBack} width={20} alt="cancel" />
            <span className="text-blue-primary font-bold transition-all">Regresar</span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <h3 className="text-xl text-black-primary font-bold">Tarjeta de débito</h3>

          <div className="w-full flex flex-col gap-4 rounded-lg bg-[#f0f0f0] p-4 lg:max-w-4xl">
            <div className={`flex flex-col gap-2 lg:w-96`}>
              <label className="font-bold">Número de tarjeta de débito</label>
              <input className={`w-full ${cardErr ? "border-red" : ""}`} maxLength="16" onChange={(e) => setCard(e.target.value)} value={card} type="text" />
              {currentChange ? <span className="text-red">Ya tienes un trámite en proceso.</span> : null}
              {cardErr ? <span className="text-red">Numero de tarjeta incorrecto.</span> : null}
            </div>
            <p className="font-bold">No se aceptan tarjetas de las siguientes instituciones: AFIRME, BANCOPPEL, BANKAOOL, BANREGIO, BANSEFI, BANAMEX SALDAZO, FAMSA, STP, UNAGRA, WALMART</p>
            <div className="flex flex-col lg:flex-row gap-4">
              <Button className={`self-start`} onClick={checkClabe}>
                Guardar
              </Button>
              <div className="flex gap-2 cursor-pointer items-center" onClick={() => setSection("home")}>
                <img src={svgCancel} width={20} alt="cancel" />
                <span className="text-red font-bold transition-all">Cancelar</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(PaymentDebit);
