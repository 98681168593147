import React, { useState, useEffect } from "react";
import "./calculator.scss";
import "../register/RegisterCalculator.scss";
import Slider from "./Slider";
import { BallClipRotate } from "react-pure-loaders";
import { getToken, getConfiguration, getSimulation } from "../../services/api.js";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { momentEs } from "../../services/moment";
import { Link, withRouter } from "react-router-dom";
import { currencyFormatter, decimalFormatter } from "../../utils/format";
import Button from "../common/Button";
import { useAnalytics } from "../../hooks/use-analytics";
import imgMoney from "../../assets/img/IconCoin02.png";

const idProduct = 4;

const Calculator = (props) => {
  const [monto, setMonto] = useState(null);
  const [firstPaymentAmount, setFirstPaymentAmount] = useState(0);
  const [plazo, setPlazo] = useState(null);
  const [periodicidad] = useState(3);
  const [interesIVA, setInteresIVA] = useState(null);
  const [fecha, setFecha] = useState(null);
  const [, setAmortizationTable] = useState([]);
  const [commision, setCommision] = useState(0);
  const [tkn, setToken] = useState("");
  const { dataLayerPushEvent } = useAnalytics();

  let idClient = 0;

  const [config, setConfig] = useState(null);

  const loadSimulation = () => {
    setMonto(2000);
    setPlazo(1);
    setConfig("error");
    setInteresIVA(0);
    setFecha("DD/MM/AAA");
    setFirstPaymentAmount(0);
    setCommision(120);
  };

  const initialConfiguration = async () => {
    const response = await getToken();
    setToken(response.data.token);
    if (response.status !== 200) return loadSimulation();
    getConfiguration(idProduct, response.data.token).then((res) => {
      const { data } = res;
      if (res.status === 200) {
        setMonto(data.defaultAmount);
        setPlazo(data.frequencies[0].frequencyTerm.defaultValue);
        setConfig(data);
        return simulate(data.defaultAmount, 3, data.frequencies[0].frequencyTerm.defaultValue, response.data.token);
      }
      setConfig("error");
    });
  };

  const simulate = async (amount, freq, term, token) => {
    if (!amount || amount < 1) return;
    getSimulation(idProduct, amount, freq, term, idClient, token).then((res) => {
      const { data } = res;
      if (res.status === 200) {
        setInteresIVA(data.interest);
        setFecha(data.firstDueDate);
        setFirstPaymentAmount(data.firstPaymentAmount);
        setAmortizationTable(data.amortizationTable);
        return setCommision(data.commision);
      }
    });
  };

  const updateMonto = (val) => {
    setMonto(val);
  };

  const updatePlazo = (val) => {
    setPlazo(val);
  };

  const setData = () => {
    sessionStorage.setItem("proposal", JSON.stringify({ idProduct, monto, periodicidad, plazo }));
    dataLayerPushEvent("Begin_Registration");
    if (sessionStorage.getItem("proposal")) return props.history.push("/registration");
  };

  const validSimulate = () => {
    if (config !== null && config !== "error") simulate(monto, periodicidad, plazo, tkn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    initialConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {config === "error" ? (
        <div className="lg:rounded-lg lg:mt-4 overflow-hidden bg-green-primary flex flex-col rounded-xl h-auto lg:max-w-lg text-sm w-full py-4 lg:px-4 px-4 gap-4">
          <div className="bg-white rounded-xl shadow-sm flex justify-center items-center flex-col py-4 px-4 lg:px-8 xl:pb-8 xl:pt-4 gap-4">
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-green-primary text-2xl" />
            <h2 className="text-center text-xl">Servidor no disponible, intenta más tarde.</h2>
          </div>
        </div>
      ) : config !== null && fecha !== null ? (
        <div className="lg:rounded-lg lg:mt-0 overflow-hidden bg-green-primary flex flex-col rounded-xl h-auto lg:max-w-lg text-sm w-full py-4 lg:px-4 px-4 gap-4">
          <div className="bg-white rounded-xl shadow-sm flex justify-center items-center flex-col py-4 px-4 lg:px-8 xl:pb-8 xl:pt-4 gap-4">
            <div className="w-full flex flex-col">
              <div className="flex justify-space w-full justify-between items-center">
                <p className="text-black-primary font-bold text-md">Monto total</p>
                <span className="text-green-primary text-lg font-bold">${decimalFormatter.format(monto)}</span>
              </div>
              <Slider config={config} min={config.minAmount} max={config.maxAmount} step={config.stepAmount} value={monto} labelFormatter={currencyFormatter} update={updateMonto} validSimulate={validSimulate} />
              {monto > 2000 && <div className="w-full text-gray-600 mt-6 bg-white p-2 text-xs text-center rounded-md transition-all font-bold">¿Eres cliente nuevo y necesitas un monto mayor a $2,000? Aumenta el límite en tu próximo préstamo.</div>}
            </div>
          </div>
          {process.env.REACT_APP_SITE !== "mivivus" && (
            <div className="bg-white rounded-xl shadow-sm flex justify-center items-center flex-col py-4 px-4 lg:px-8 xl:pb-8 xl:pt-4 gap-4">
              <div className="w-full">
                <div className="flex justify-space w-full justify-between items-center">
                  <p className="text-black-primary font-bold text-md">Plazo</p>
                  <span className="text-green-primary text-xl font-bold">{plazo} días</span>
                </div>
                <Slider
                  config={config}
                  min={config !== "error" ? (periodicidad === 2 ? config.frequencies[1].frequencyTerm.minTerm : config.frequencies[0].frequencyTerm.minTerm) : 0}
                  max={config !== "error" ? (periodicidad === 2 ? config.frequencies[1].frequencyTerm.maxTerm : config.frequencies[0].frequencyTerm.maxTerm) : 0}
                  step={1}
                  value={plazo}
                  labelFormatter={decimalFormatter}
                  legendFormatter={"días"}
                  update={updatePlazo}
                  validSimulate={validSimulate}
                />
              </div>
            </div>
          )}
          {/* <div className="bg-blue-lighter text-blue-primary font-bold p-4 flex items-center text-center justify-center rounded-lg">¡Paga 0% de intereses si es tu primer préstamo!</div> */}

          <div className="flex lg:hidden w-full mt-4">
            <Button className="w-full lg:w-auto self-center bg-orange-primary hover:bg-orange-light active:bg-orange-dark" icon={imgMoney} onClick={setData}>
              Solicítalo ya
            </Button>
          </div>
          <div className="flex flex-col gap-2 text-lg ">
            <div className="flex justify-between py-2 text-white items-center">
              <p>Interés</p>
              <div>
                <p className="font-bold text-white">
                  {interesIVA.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  <small> MXN</small>
                </p>
                <p className="text-xs text-right">IVA incluído</p>
              </div>
            </div>
            <div className="flex justify-between py-2 text-white items-center">
              <p className="text-left w-1/2">Comisiones</p>
              <div>
                <p className="font-bold text-right text-white">
                  {commision.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  <small> MXN</small>
                </p>
                <p className="text-right text-xs">IVA incluído</p>
              </div>
            </div>
            <div className="flex justify-between py-2 text-white items-center">
              <p>
                <p>Monto a pagar</p>
              </p>
              <div>
                <p className="font-bold text-white">
                  {firstPaymentAmount.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  <small> MXN</small>
                </p>
                <p className="text-right text-xs">IVA incluído</p>
              </div>
            </div>
            <div className="flex justify-between pt-2 pb-4 text-white items-center">
              <p className="text-left">{process.env.REACT_APP_SITE === "mivivus" ? <p>Fecha de pago</p> : <p>Fecha de pago</p>}</p>
              <p className="text-white font-bold">{momentEs(fecha).format("D/MMM/Y")}</p>
            </div>
          </div>

          <div className="hidden lg:flex lg:w-full lg:flex-col gap-4 lg:items-center">
            <Button className="w-full lg:w-auto self-center bg-orange-primary hover:bg-orange-light active:bg-orange-dark" onClick={setData} icon={imgMoney}>
              Solicítalo YA
            </Button>

            <Link to="/login" className="cursor-pointer text-white hover:text-gray-50 active:text-gray-100 transition-all text-lg font-bold shadow-none">
              ¿Ya tienes cuenta?
            </Link>
          </div>
        </div>
      ) : (
        <div className="lg:rounded-lg lg:mt-12 overflow-hidden bg-green-primary flex flex-col rounded-xl h-auto lg:max-w-lg text-sm w-full py-4 lg:px-4 px-4 gap-4">
          <div className="bg-white rounded-xl shadow-sm flex justify-center items-center flex-col py-4 px-4 lg:px-8 xl:pb-8 xl:pt-4 gap-4">
            <BallClipRotate loading color={"#56AF31"} />
            <h2 className="text-center text-xl">Cargando datos...</h2>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(Calculator);
