import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import TagManager from "react-gtm-module";
// import ReactPixel from 'react-facebook-pixel'
import Pixel from "./pixel";

export const IdentityValidated = () => {
  //TAG MANAGER
  const tagManagerArgs = {
    dataLayer: {
      event: "pageChange",
      page: {
        url: "/identity-validated",
        referrer: sessionStorage.getItem("utm") || "/",
      },
    },
    dataLayerName: "dataLayer",
  };
  TagManager.dataLayer(tagManagerArgs);
  //TAG MANAGER
  // let options = {autoConfig: true, debug: true}
  // ReactPixel.init('285010758598337', options)
  // ReactPixel.pageView()
  return (
    <div className="bg-white relative w-full flex justify-center flex-1">
      <div className="container flex flex-col gap-8 px-4 py-12 self-start">
        <div className="w-full flex flex-col gap-4 flex-1 mb-32">
          <h1 className="text-3xl font-extra-bold text-green-primary"> Tu préstamo ha sido aprobado.</h1>
          <p className="text-xl">Estaremos contactandote para confirmarte cuándo recibirás el préstamo.</p>
          <Pixel name="PIXEL 1" />
        </div>
      </div>
    </div>
  );
};
