import React from "react";

export const Manteinance = () => {
  return (
    <div className="app-container">
      <div className="manteinance-page">
        <div className="left-manteinance">
          <h1>PÁGINA EN</h1>
          <h2>MANTENIMIENTO</h2>
          <h3>¡Nos encontramos trabajando para atenderte mejor!</h3>
          <h4>En breve estaremos nuevamente disponibles</h4>
          <h4>Lamentamos los inconvenientes</h4>
        </div>
        <div className="right-manteinance">
          <img src="/img/man.png" alt="Mantenimiento Doctor Peso" />
        </div>
      </div>
    </div>
  );
};
