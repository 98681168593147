import { requestAdditionalAmount, requestExtension, getToken } from "../../../services/api";

export const checkLoan = async (user, setServerError, setCanSolicitMore) => {
  const idProduct = 4;

  const response = await getToken();
  if (!response) {
    // console.log("NO RESPONSE!")
    setServerError("Error en el servidor, Token");
    return;
  } else {
    // console.log("RESPONSE: " + response)
  }
  const validToken = response.data.token;

  const data = {
    idProduct,
    idCustomer: user.customerId,
    idAction: 1,
    customerIp: sessionStorage.getItem("ip") ? sessionStorage.getItem("ip") : "0.0.0.0",
    newLoanAmount: 0,
    newLoanTerm: 0,
    newLoanFrequency: 0,
  };

  requestAdditionalAmount(data, validToken).catch((err) => {
    if (err.response) {
      if (err.response.status !== 200 && err.response.status !== 201) {
        setCanSolicitMore(false);
        // console.log("NO PUEDES SOLICITAR MAS DINERO!")
        if (user.eMail === "demo@demo.com") {
          setCanSolicitMore(true);
          // console.log("ERES DEMO, PUEDES SOLICITAR MAS DINERO!")
        }
      }
    }
  });
};

export const loadExtension = async (setExtensionData, setServerError, setExtensionError, setExtStatus) => {
  const idProduct = 4;
  const user = await JSON.parse(sessionStorage.getItem("loggedUser"));
  const demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
  if (user === null) {
    console.log("Error in loadExtension, user is null!");
    return null;
  }
  if (demoUser || user.eMail === "demo@demo.com") return setExtensionData([{ days: 30, newDueDate: new Date(), extensionFee: 450 }]);
  const response = await getToken();
  if (!response) return setServerError(true);
  const validToken = response.data.token;
  const data = {
    idAction: 1,
    days: 0,
    idCustomer: user.customerId,
    idProduct,
  };
  requestExtension(data, validToken).then((res) => {
    const { data } = res;
    if (!data) return setExtensionError("Server Error: 400");
    if (data) {
      setExtensionData(data.details);
      if (data.rejectReasons.length) {
        setExtensionError(data.rejectReasons[0].reason);
      }
    } else {
      setExtensionError(data.rejectReasons[0].reason);
    }
  });
};

export const checkExtension = async (setCanExtend) => {
  const idProduct = 4;
  const user = await JSON.parse(sessionStorage.getItem("loggedUser"));
  const demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
  if (demoUser || user.eMail === "demo@demo.com") return;
  const response = await getToken();
  if (!response) return;
  const validToken = response.data.token;
  const data = {
    idAction: 1,
    days: 0,
    idCustomer: user.customerId,
    idProduct,
  };

  requestExtension(data, validToken).then((res) => {
    const { data } = res;
    if (!data) {
      setCanExtend(false);
    } else {
    }
    if (data) {
      if (data.rejectReasons.length) {
        setCanExtend(false);
      } else {
        setCanExtend(true);
      }
    }
  });
};
