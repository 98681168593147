import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import BankOption from "../pay-components/liquidate-components/BankOption";
import DebitOption from "../pay-components/liquidate-components/DebitOption";
import CashOption from "../pay-components/liquidate-components/CashOption";
import { getToken, requestExtension } from "../../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faTimes } from "@fortawesome/free-solid-svg-icons";
import MoveItem from "./MoveItem";
import { momentEs } from "../../../../services/moment";

import { loadExtension } from "../dashboardChecks.js";
import Button from "../../../common/Button";

const idProduct = 4;
const MoveOption = ({ user, loanNumber }) => {
  const [willPay, setWillPay] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [extensionData, setExtensionData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [extensionError, setExtensionError] = useState(null);
  const [reference, setReference] = useState(null);
  const [paymentTab, setPaymentTab] = useState(1);

  const setExtension = async (days) => {
    setWillPay(false);
    setReference(null);
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    let loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (demoUser || loggedUser.eMail === "demo@demo.com") {
      setReference(123456789);
      return setWillPay(true);
    }
    let response = await getToken();
    if (!response) return setServerError(true);
    let validToken = response.data.token;
    const data = {
      idAction: 2,
      days,
      idCustomer: user.customerId,
      idProduct,
    };

    requestExtension(data, validToken).then((res) => {
      const { data } = res;
      if (data) {
        // ALLOW EXTENSION
        setWillPay(true);
        setReference(data.details[0].payCashReference);
      }
      if (!data) setExtensionError("Server Error: 400");
    });
  };

  useEffect(() => {
    loadExtension(setExtensionData, setServerError, setExtensionError);
  }, []);

  return (
    <>
      <div className="flex flex-col gap-4 bg-blue-lighter rounded-lg p-4" id="extensions">
        <h4 className="text-blue-primary font-bold text-xl">Recuerda realizar el pago de tu extensión para hacerla válida y recorrer la fecha de pago de tu préstamo.</h4>
        {!extensionError && <p className="text-black-primary">Tienes disponibles los siguientes esquemas de extensión:</p>}
        {extensionError && <p className="font-bold text-red">{extensionError}</p>}
        <div className="flex gap-4 flex-col lg:flex-row">
          {extensionData?.map((element, index) => (
            <MoveItem key={index} element={element} selected={selected} extensionError={extensionError} serverError={serverError} setExtension={setExtension} setSelected={setSelected} />
          ))}
        </div>
        {serverError || extensionError ? (
          <span className="text-[#e0e0e0] self-center"> Error al solicitar extensión</span>
        ) : (
          <Button
            className={`self-center bg-blue-primary hover:bg-blue-light active:bg-blue-dark ${!selected ? "pointer-events-none bg-[#e0e0e0]" : ""}`}
            onClick={
              extensionError
                ? null
                : () => {
                    setExtension(selected);
                  }
            }
          >
            Solicitar extensión
          </Button>
        )}
      </div>

      {willPay && (
        <div
          className="fixed top-0 left-0 w-full h-full overflow-hidden z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setSelected(null);
              setWillPay(false);
            }
          }}
        >
          <div className="py-8 px-4 flex flex-col gap-4 relative bg-white max-w-5xl rounded-xl mx-2 max-h-screen overflow-auto">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={(e) => {
                setSelected(null);
                setWillPay(false);
              }}
              width={24}
              className="absolute top-4 right-4 cursor-pointer"
            />
            <h4 className="text-xl font-bold text-green-primary">¿Cómo pagar el servicio para recorrer fecha de pago?</h4>
            <p className="font-bold">Detalles de pago</p>
            <div className="rounded-lg bg-green-light p-4 flex justify-around">
              <div className="flex flex-col gap-2">
                <span>Costo de la extensión</span>
                <span className="text-green-primary text-xl font-bold">
                  {extensionData?.find((e) => e.days === selected)?.extensionFee.toLocaleString("en-US", { style: "currency", currency: "USD" })} <small>IVA Incluido</small>
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <span>Nueva fecha de pago</span>
                <span className="text-green-primary text-xl font-bold">{momentEs(extensionData?.find((e) => e.days === selected)?.newDueDate).format("D/MMM/Y")}</span>
              </div>
            </div>
            <div className="flex flex-col bg-white rounded-lg p-4">
              <div
                className={`flex justify-between cursor-pointer p-4 rounded-t-lg ${paymentTab === 1 ? "bg-[#f0f0f0]" : ""}`}
                onClick={() => {
                  setPaymentTab(paymentTab === 1 ? 0 : 1);
                }}
              >
                <span className="font-bold text-black-primary text-xl">Efectivo en tiendas</span>
                <div className="text-black-primary text-lg my-0 mr-4">{paymentTab === 1 ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
              </div>
              {paymentTab === 1 && (
                <div className="bg-[#f0f0f0] rounded-b-lg">
                  <CashOption extensionReference={reference} moveImgWidth={"200px"} />
                </div>
              )}
              <div
                className={`flex justify-between cursor-pointer p-4 rounded-t-lg ${paymentTab === 2 ? "bg-[#f0f0f0]" : ""}`}
                onClick={() => {
                  setPaymentTab(paymentTab === 2 ? 0 : 2);
                }}
              >
                <span className="font-bold text-black-primary text-xl">Transferencia</span>
                <div className="text-black-primary text-lg my-0 mr-4">{paymentTab === 2 ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
              </div>
              {paymentTab === 2 && (
                <div className="bg-[#f0f0f0] rounded-b-lg">
                  <BankOption />
                </div>
              )}
              <div
                className={`flex justify-between cursor-pointer p-4 rounded-t-lg ${paymentTab === 3 ? "bg-[#f0f0f0]" : ""}`}
                onClick={() => {
                  setPaymentTab(paymentTab === 3 ? 0 : 3);
                }}
              >
                <span className="font-bold text-black-primary text-xl">Tarjeta de Débito</span>
                <div className="text-black-primary text-lg my-0 mr-4">{paymentTab === 3 ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}</div>
              </div>
              {paymentTab === 3 && (
                <div className="bg-[#f0f0f0] rounded-b-lg">
                  <DebitOption moveLoan={true} reference={loanNumber} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MoveOption;
