import React from "react";
import { BallClipRotate } from "react-pure-loaders";
import { momentEs } from "../../../../services/moment";
import Button from "../../../common/Button";

const MoveItem = ({ element, selected, extensionError, serverError, setExtension, setSelected }) => {
  const { days, newDueDate, extensionFee } = element;

  return (
    <div className={`flex-1 w-full border border-blue-primary p-8 flex flex-col gap-4 cursor-pointer ${selected === days ? "bg-blue-primary text-white" : ""}`} onClick={() => setSelected(days)}>
      <div className="flex justify-between gap-4 flex-col lg:flex-row items-center">
        <div className="flex flex-col gap-1 justify-center items-center">
          <p className={`${selected === days ? "text-white" : "text-black-primary"}`}>Costo de la extensión</p>
          <p className={`text-xl font-bold ${selected === days ? "text-white" : "text-blue-primary"}`}>
            {extensionFee.toLocaleString("en-US", { style: "currency", currency: "USD" })}
            <span className="text-xs"> MXN</span>
          </p>
        </div>
        <div className="flex flex-col gap-1 justify-center items-center">
          <p className={`${selected === days ? "text-white" : "text-black-primary"}`}>Plazo</p>
          <p className={`text-xl font-bold ${selected === days ? "text-white" : "text-blue-primary"}`}>
            {days} <span className="font-light text-xs">días</span>
          </p>
        </div>
        <div className="flex flex-col gap-1 justify-center items-center">
          <p className={`${selected === days ? "text-white" : "text-black-primary"}`}>Nueva fecha de pago</p>
          <p className={`text-xl font-bold ${selected === days ? "text-white" : "text-blue-primary"}`}>{momentEs(newDueDate).format("D/MMM/Y")}</p>
        </div>
      </div>
    </div>
  );
};

export default MoveItem;
