import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { BallBeat } from "react-pure-loaders";
import cookie from "react-cookies";
import Button from "../common/Button";

const PasswordRecoveryEmail = (props) => {
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);

  const confirmation = async () => {
    setLoading(true);
    let rex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email || !rex.test(email.toLowerCase())) {
      setLoading(false);
      return setEmailError(true);
    }
    sessionStorage.setItem("recoveryEmail", email);
    return props.history.push("/recupera-opcion");
    // let response = await getToken()
    // if(!response) return
    // let validToken = response.data.token
    // const data = {
    //   eMail:email,
    //   idProduct
    // }
    // recoverPassword(data, validToken)
    // .then(res => {
    //   if(res.data.codigo === '200'){
    //     setEmailError(false)
    //   }
    //   setEmailError(true)
    //   setLoading(false)
    // })
    // .catch(err => {
    //   console.log(err)
    //   setEmailError(true)
    //   setLoading(false)
    // })
  };

  useEffect(() => {
    if (sessionStorage.getItem("loggedUser") && cookie.load("token")) props.history.push("/dashboard/initial");
  }, [props]);

  return (
    <div className="bg-white relative w-full flex justify-center items-start flex-1">
      <div className="container flex flex-col gap-4 p-4">
        <div className="flex flex-col gap-4 py-4 max-w-4xl w-full self-start">
          <div className="w-full flex flex-col gap-2">
            <h1 className="text-3xl font-extra-bold text-black-primary">Recupera tu contraseña</h1>
            <p>Ingresa tu correo electrónico para recuperar el acceso.</p>
          </div>
          <div className="w-full flex flex-col gap-4 rounded-lg bg-[#f0f0f0] p-4">
            <div className={`flex flex-col gap-2`}>
              <label className="font-bold">Correo electrónico</label>
              <input className={`w-full lg:w-96 ${emailError ? "border-red" : ""}`} placeholder="ejemplo@email.com" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
              {emailError ? <span className="text-red ">Correo electrónico inválido</span> : null}
            </div>
            <Button className="self-start" onClick={confirmation}>
              {loading ? <BallBeat color={"#fff"} loading /> : "Seleccionar método de recuperación"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PasswordRecoveryEmail);
