import React from "react";

export const Restructure = () => {
  return (
    <div className="flex justify-center items-center flex-1 flex-col ">
      <div className="container px-4 py-12 flex gap-4 flex-col">
        <div className="flex flex-col gap-2 w--full lg:w-2/3">
          <h2 className="text-3xl font-extra-bold">¿No puedes realizar el pago el día de vencimiento?</h2>
          <h2 className="text-3xl font-extra-bold">Te ofrecemos recorrer la fecha de pago de tu préstamo</h2>
          <p className="text-lg">
            A veces las cosas no van de acuerdo a lo planeado y un poco de flexibilidad financiera puede realmente ayudar. Por eso te brindamos más tiempo para realizar tu pago, ofreciéndote mover la fecha de vencimiento. Sólo ingresa a tu perfil en{" "}
            <a href="/login" className="text-blue-primary font-bold">
              doctorpeso.com.mx
            </a>{" "}
            y acepta términos y condiciones. Si tienes dudas, contáctanos en WhatsApp.
          </p>
          <ol className="text-lg">
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['1'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              <p>
                <strong>Ventajas de recorrer la fecha de pago de tu préstamo:</strong>
                <br />- Mantendrás un excelente historial crediticio evitando que tu préstamo sufra atraso.
                <br />- Tienes más tiempo para administrar tus pagos.
                <br />- No afecta tu historial, por lo tanto, podrás continuar solicitando préstamos con nosotros.
              </p>
            </li>
            <li className="text-lg before:min-w-[40px] clear-both flex items-start content-center justify-items-center my-4 before:content-['2'] before:clear-both before:flex before:content-center before:items-center before:justify-center before:h-[40px] before:mr-4 before:text-2xl before:leading-4 before:text-orange-primary before:text-center  before:rounded-full before:bg-orange-lighter before:font-bold ">
              <p>
                <strong>Formas de pago:</strong>
                <br />
                Conoce los medios de pago ingresando a{" "}
                <strong>
                  <a className="text-blue-primary font-bold" href={"/perfil"}>
                    Mi Perfil
                  </a>
                  .
                </strong>
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
